import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import Header from 'components/common/Header.js';
import SingleCreative from 'components/common/SingleCreative.js';
import SingleTool from 'components/common/SingleTool.js';
import { fetch } from 'actions/creatives.js';


const TOOLS_TEMPLATES = ['tool-link'];

class Creatives extends React.Component {
  static propTypes = {
    creatives: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(fetch());
  }

  getCreativesTools() {
    return this.props.creatives.get('data').filter(creative => TOOLS_TEMPLATES.includes(creative.get('templateType').toLowerCase())); // TODO: search by templateType name
  }

  getCreativesSnippets() {
    return this.props.creatives.get('data').filter(creative => !TOOLS_TEMPLATES.includes(creative.get('templateType')));
  }

  renderTools() {
    const tools = this.getCreativesTools().valueSeq()
      .sortBy(creative => creative.get('id'))
      .sortBy(creative => -creative.get('sortWeight'))
      .toList();

    return tools.map(tool => {
      return (
        <SingleTool
          key={ tool.get('id') }
          creative={ tool }
          user={ this.props.user }
        />
      );
    });
  }

  renderCreativesSnippets() {
    const creatives = this.getCreativesSnippets()
      .valueSeq()
      .sortBy(creative => creative.get('id'))
      .sortBy(creative => -creative.get('sortWeight'))
      .toList();

    return creatives.map(creative => {
      return (
        <SingleCreative
          key={ creative.get('id') }
          creative={ creative }
          user={ this.props.user }
        />
      );
    });
  }

  render() {
    return (
      <DocumentTitle title="Referoo creatives">
        <div>
          <Header />
          <section className="flash">
            <div className="grid">
              <h3>Choose creatives</h3>
              <p>
                Creatives are links, banners and forms you can include inside
                your website, emails or social media. To use one (or more), just
                copy the code from the creative and paste it into the source of
                your marketing channel.
                Each creative has a different linked &quot;<em>?ref=</em>&quot; parameter, but they are all tracked to you.
              </p>
            </div>
          </section>

          <section className="creative__press-kit">
            <div className="grid">
              <h4>Additional Codeable Resources</h4>
              <div className="creative__press-kit__links-wrapper">
                <a href="https://storage.googleapis.com/cdbl-general/referoo/CDBL_Press_Kit.zip" download="CDBL_Press_Kit.zip">Our Press Kit</a>
                <span className="separator--horizontal"></span>
                <a href="https://storage.googleapis.com/cdbl-general/referoo/Codeable_Fact_Sheet.pdf" download="Codeable_Fact_Sheet.pdf" target="_blank" rel="noopener noreferrer">Our Fact Sheet</a>
              </div>
            </div>
          </section>


          <div className="grid" style={{ marginBottom: '2rem' }}>
            <div className="grid__two-thirds">
              <h5>TOOLS</h5>
              { this.renderTools() }
            </div>
            <div className="grid__third grid__last">

            </div>
          </div>

          <div className="grid">
            <div className="grid__two-thirds">
              <h5>CREATIVES</h5>
            </div>
            <div className="grid__third grid__last">
              <h5>Preview</h5>
            </div>

            { this.renderCreativesSnippets() }
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

function select(state) {
  return {
    creatives: state.creatives,
    user: state.user,
  };
}

export default connect(select)(Creatives);
