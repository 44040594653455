import keyMirror from 'keymirror';
const CALL_API = Symbol.for('Call API');

export const actions = keyMirror({
  CREATIVES_FETCH: null,
  CREATIVES_FETCH_SUCCESS: null,
  CREATIVES_FETCH_FAILURE: null,
});

export function fetch() {
  return {
    [CALL_API]: {
      types: [
        actions.CREATIVES_FETCH,
        actions.CREATIVES_FETCH_SUCCESS,
        actions.CREATIVES_FETCH_FAILURE,
      ],
      endpoint: '/creatives',
    },
  };
}
