import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Button from 'components/form/ApiButton.js';
import FieldError from 'components/form/FieldError.js';
import { withdraw, showError, clearErrors, withdrawalDone } from 'actions/user.js';
import { close } from 'actions/modal.js';
import { formatMoney as toMoney } from 'accounting';
import { Link } from 'react-router-dom';

class WithdrawModal extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      type: ['bank', 'paypal'][0],
      errors: null,
    };
    this.changeType = this.changeType.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.showError = this.showError.bind(this);
  }

  componentDidMount() {
    this.clearErrors();
  }

  showError(obj) {
    this.setState({
      errors: true,
    });
    this.props.dispatch(showError(obj));
  }

  withdraw(event) {
    event.preventDefault();
    const amount = this.refs.amount.value;
    const payment = this.props.user.get('payment');
    const parsedAmount = amount.match(/^[0-9.]+$/);
    let requestAmount = 0;
    if (Array.isArray(parsedAmount) && parsedAmount.length) {
      requestAmount = parseFloat(parsedAmount[0]);
    }
    const withdrawalData = {
      partner_id: this.props.user.get('id'),
      withdrawal_amount: amount,
    };
    if (payment.get('preferred') === 'bank') {
      withdrawalData.bank = payment.get('bank');
      withdrawalData.swift = payment.get('swift');
    }
    if (payment.get('preferred') === 'paypal') {
      withdrawalData.paypal = payment.get('paypal');
    }
    /* VALIDATION */
    if (isNaN(requestAmount) || requestAmount === 0 || parseFloat(this.props.user.get('currentBalance')) < parseFloat(this.refs.amount.value)) {
      this.showError({ code: '113', attribute: 'amount' });
    }
    if (!this.state.errors) {
      this.props.dispatch(withdraw(withdrawalData));
    }
  }

  clearErrors() {
    this.props.dispatch(clearErrors());
    this.setState({
      errors: null,
    });
  }

  close() {
    this.props.dispatch(close());
    this.props.dispatch(withdrawalDone());
  }

  changeType(type) {
    this.clearErrors();
    this.setState({
      type,
    });
    this.forceUpdate();
  }

  render() {
    const ballance = parseFloat(this.props.user.get('currentBalance'));
    const errors = this.props.user.get('errors').toJS() || [];
    const paypal = (
      this.props.user.getIn(['payment', 'preferred']) === 'paypal' || this.props.user.getIn(['payment', 'paypal'])
    );
    const isUS = this.props.user.getIn(['payment', 'bank', 'country']) === 'US';
    const isNonUS = this.props.user.getIn(['payment', 'bank', 'country']) === '';
    const holderName = this.props.user.getIn(['payment', 'bank', 'holderName']);
    const accountNumber = this.props.user.getIn(['payment', 'bank', 'accountNumber']);
    const iban = this.props.user.getIn(['payment', 'bank', 'iban']);
    const isPreferredBank = this.props.user.getIn(['payment', 'preferred']) === 'bank';
    const allowWithdraw = (paypal || holderName) && ballance > 0;
    return (
      <div className="modal-content">
        <h1>Withdrawal request</h1>
        <form className="form" onSubmit={ this.withdraw.bind(this) }
          style={{ display: (this.props.user.get('withdrawSuccessful') ? 'none' : 'block') }}
        >
          <p className="form__explanation" style={{ display: allowWithdraw ? 'block' : 'none' }}>
            Enter the amount you'd like to withdraw in the field below. <br />
          </p>

          <p className="form__explanation">
            You currently have <strong>{ toMoney(ballance) }</strong> available for withdrawal.
          </p>

          <p className="form__explanation" style={{ display: !isPreferredBank && paypal ? 'block' : 'none' }}>
            The withdrawal will be processed to your Paypal's account: <strong>{ this.props.user.get('payment').get('paypal') }</strong>.
            <br />
            <br />
            If you'd like to change your withdrawal settings, go to <Link to="/settings/payment" onClick={ this.close.bind(this) }>Settings</Link>
          </p>

          <p className="form__explanation" style={{ display: isPreferredBank && isUS && holderName ? 'block' : 'none' }}>
            The withdrawal will be processed to your bank account:
            <br />
            <br />
            Name of the account holder: <strong>{ holderName }</strong>
            <br />
            Account number: <strong>{ accountNumber }</strong>
            <br />
            <br />
            If you'd like to change your withdrawal settings, go to <Link to="/settings/payment" onClick={ this.close.bind(this) }>Settings</Link>
          </p>

          <p className="form__explanation" style={{ display: isPreferredBank && isNonUS && holderName ? 'block' : 'none' }}>
            The withdrawal will be processed to your bank account:
            <br />
            <br />
            Name of the account holder: <strong>{ holderName }</strong>
            <br />
            IBAN: <strong>{ iban }</strong>
            <br />
            <br />
            If you'd like to change your withdrawal settings, go to <Link to="/settings/payment" onClick={ this.close.bind(this) }>Settings</Link>
          </p>

          <p className="form__explanation" style={{ display: allowWithdraw ? 'block' : 'none' }}>
            Please note it may take <strong>up to 2 business days </strong>
            before your request is processed.
          </p>

          <p className="form__explanation" style={{ display: allowWithdraw ? 'block' : 'none' }}>
            Amount
            <span style={{ marginLeft: '10px' }}>
              <label htmlFor="amount" className="label--prefix">$</label>
              <input
                type="text" id="amount"
                ref="amount" className="input--narrow"
                defaultValue={ ballance }
                onFocus={ this.clearErrors }
              />
            </span>
            <FieldError attribute="amount" code="113" errors={ errors }>
              Enter a valid amount.
            </FieldError>
          </p>


          <div className="form__row" style={{ display: allowWithdraw ? 'block' : 'none' }}>
            <Button
              className="button--small"
              text="Request withdrawal"
              active={ this.props.user.get('isFetching') || false }
              activeText="Requesting..."
            />

            <span className="text--faded">or </span>
            <a href="#" onClick={ this.close.bind(this) }>cancel</a>
          </div>

          <p className="form__explanation" style={{ display: ballance > 0 && !paypal && !holderName ? 'inline' : 'none' }}>
            But first, please go to <Link to="/settings/payment" onClick={ this.close.bind(this) }>Settings</Link> and select preferred method of payment.
            <br />
            <br />
            <Link className="button--small button" to="/settings/payment" onClick={ this.close.bind(this) }>Settings</Link>
            <span className="text--faded">or </span>
            <a href="#" onClick={ this.close.bind(this) }>cancel</a>
            <br />
            <br />
          </p>
        </form>
      </div>
    );
  }
}

function select(state) {
  return {
    user: state.user,
  };
}

export default connect(select)(WithdrawModal);
