import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import 'react-date-picker/index.css';
import LineChart from '../../components/chart/LineChart.js';
import { fetch as fetchPerformance } from '../../actions/performance.js';
import Datepicker from '../Datepicker';
import WithdrawFunds from './WithdrawFunds';

function ymd(momentObj) {
  return momentObj.format('YYYY-MM-DD');
}

const withdrawButtonStyle = {
  backgroundColor: '#333',
  verticalAlign: 'middle',
  float: 'right',
};

const rangeContainerStyle = {
  fontSize: '1.2rem',
  float: 'right',
};

const datePickerStyle = {
  position: 'relative',
  float: 'right',
  height: '0.01px',
};

/**
 * TODO: this component is one of the few to not follow Immutable rules. Fix it.
 */
class DefaultDashboard extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    datepickerRange: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.toggleDatePickerVisibility = this.toggleDatePickerVisibility.bind(this);
    this.closeDatePicker = this.closeDatePicker.bind(this);
    /* We have all performance data inside this user's login data, so the data is already
       available when this component mounts */
    /* get frormDate and toDate from sorted array (first and last element of date array) */
    const dates = props.user.get('performance').get('data').toJS().map(event => event.date).sort();
    this.state = {
      fromDate: dates[0],
      toDate: dates[dates.length - 1],
      showDatePicker: false,
    };
    this.state.range = [moment(this.state.fromDate), moment(this.state.toDate)];
    this.props.dispatch(fetchPerformance([ymd(moment(this.state.fromDate)), ymd(moment(this.state.toDate))]));
  }

  toggleDatePickerVisibility(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showDatePicker: !this.state.showDatePicker });
  }

  closeDatePicker() {
    this.setState({ showDatePicker: false });
  }

  isReverseReward(plan) {
    return plan.planType === 'reverse-reward';
  }

  renderReward(user) {
    if (user.plan.rewardType === '%') {
      return `${user.plan.rewardAmount} ${user.plan.rewardType}`;
    }

    return `${user.plan.rewardType} ${user.plan.rewardAmount}`;
  }

  renderRewardDescription(user) {
    const defaultDescription = 'What you get for each converted visitor';
    const reverseRewardDescription = 'Given to new clients using your link';

    if (this.isReverseReward(user.plan)) return reverseRewardDescription;

    return defaultDescription;
  }

  renderPlanRewardType(user) {
    const defaultPlanRewardType = 'reward';
    const couponPlanRewardType = 'coupon';

    if (this.isReverseReward(user.plan)) return couponPlanRewardType;

    return defaultPlanRewardType;
  }

  render() {
    const user = this.props.user.toJS();
    const start = this.props.datepickerRange[0] || this.state.range[0];
    const end = this.props.datepickerRange[1] || this.state.range[1];
    const { totalHits = '', totalConversions, conversionRate, totalEarnings } = user.performance;
    this.dateDialog = <Datepicker clickHandler={ this.closeDatePicker } />;
    return (
      <div className="grid" ref="grid">
        <h1>
          Performance Snapshot
          <div style={ rangeContainerStyle }>
            <button className="date-picker-button-toggle ignore-react-onclickoutside" onClick={ this.toggleDatePickerVisibility }>
              <span className="date-picker-button-toggle__text">({ `${moment(start).format('MM/DD/YY')} - ${moment(end).format('MM/DD/YY')}` })</span>
              <span className={!this.state.showDatePicker ? 'triangle-arrow-down' : 'triangle-arrow-up'}></span>
            </button>
          </div>
        </h1>
        { this.state.showDatePicker ? <div style={ datePickerStyle }>{ this.dateDialog }</div> : null }
        <p><strong>Have questions?</strong> Advanced detailed reporting available upon request to <a href="mailto:partners@codeable.io">partners@codeable.io</a></p>
        <LineChart data={ user.performance.data } type="hits" label="Visits" color="#118DC9" height="250" />
        <LineChart data={ user.performance.data } type="conversions" label="Projects completed" color="#F05A28" height="150" />
        <br />
        <section className="stats-wrap">
          <div className="stats grid__half">
            <h2 className="stats__header">
              { totalHits } <span className="stats__unit">visits</span>
            </h2>
            <p>How many times your links have been clicked.</p>
          </div>
          {
            this.isReverseReward(user.plan) ?
              <div className="stats stats--static grid__half grid__last">
                <h2 className="stats__header">
                  { this.renderReward(user) }
                  <span className="stats__unit"> { this.renderPlanRewardType(user) }</span>
                </h2>
                <p>Given to new clients using your link</p>
              </div>
              :
              <div className="stats grid__half grid__last">
                <h2 className="stats__header">
                  ${ totalEarnings } <span className="stats__unit">earnings</span>
                </h2>
                <p>How much you have earned in the selected period.</p>
              </div> }
        </section>

        <section className="stats-wrap">
          <div className="stats grid__half">
            <h2 className="stats__header">
              { totalConversions } <span className="stats__unit">projects completed</span>
            </h2>
            <p>How many visitors converted to paying clients.</p>
          </div>
          <div className="stats grid__half grid__last">
            <h2 className="stats__header">
              { conversionRate }% <span className="stats__unit">projects completed rate</span>
            </h2>
            <p>What percentage of visitors converted to customers.</p>
          </div>
        </section>

        { this.isReverseReward(user.plan) ? null :
          <section className="stats-wrap">
            <div className="stats stats--static grid__half">
              <h2 className="stats__header">
                { user.currentBalance }
                <span className="stats__unit"> current balance</span>
                <WithdrawFunds
                  location={this.props.location}
                  user={this.props.user}
                  withdrawButtonStyle={withdrawButtonStyle}
                />
              </h2>
              <p>How much you have earned but not yet withdrawn.</p>
            </div>
            <div className="stats stats--static grid__half grid__last">
              <h2 className="stats__header">
                { this.renderReward(user) }
                <span className="stats__unit"> { this.renderPlanRewardType(user) }</span>
              </h2>
              <p>What you get for each converted visitor</p>
            </div>
          </section>
        }
      </div>
    );
  }
}

function select(state) {
  return {
    user: state.user,
    datepickerRange: state.datepickerRange,
  };
}

export default connect(select)(DefaultDashboard);
