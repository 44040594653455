import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import PasswordField from 'components/form/PasswordField.js';
import { connect } from 'react-redux';
import flash from 'actions/flash.js';
import { update } from 'actions/user.js';

class Account extends React.Component {
  /**
   * These props have no .isRequired because the element itself is cloned one
   * level above. But because the props are evaluated at orignal element and not
   * cloned, we don't get them; https://github.com/facebook/react/issues/4494
   */
  static propTypes = {
    user: PropTypes.object,
    errors: PropTypes.object,
    isFetching: PropTypes.bool,
    updateData: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
  }

  update(event) {
    event.preventDefault();
    const data = {
      fullName: this.refs.fullName.value,
      email: this.refs.email.value,
      new_password: this.refs.password.refs.password.value,
      newsletter: this.refs.newsletter.checked,
    };
    this.props.updateData(data);
  }

  render() {
    const { email, fullName, receiveNewsletter } = this.props.user.toJS();

    return (
      <form className="form" onSubmit={ this.update } >
        <h1 className="bold">Settings</h1>
        <h4 className="bold">Account information</h4>
        <p className="form__explanation text-william">
          This page allows you to update the most common settings. Use the
          navigation on the left to update payment information
        </p>

        <div className="form__row">
          <label htmlFor="name">Your name</label>
          <input ref="fullName" type="text" id="name" defaultValue={ fullName } />
        </div>

        <div className="form__row">
          <label htmlFor="email">Your email</label>
          <input ref="email" type="text" id="email" defaultValue={ email } />
        </div>

        <div className="form__row">
          <label htmlFor="my_password">New password</label>
          <PasswordField ref="password" />
        </div>

        <div className="form__row">
          <input
            ref="newsletter"
            type="checkbox"
            id="receive"
            defaultChecked={ receiveNewsletter }
          />
          <label htmlFor="receive">
            <span className="checkbox__span"></span> Receive performance updates?
            <a className="tooltip" data-tip data-for="perf">?</a>
            <ReactTooltip id="perf" type="dark" effect="solid">
              <span>At the beginning of each month, you will receive an email with detailed
              performance analysis and possible suggestions.</span>
            </ReactTooltip>
          </label>
        </div>

        <div className="form__actions">
          <button className="button--small" disabled={this.props.isFetching}>{this.props.isFetching ? 'Saving...' : 'Save changes' }</button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.user.get('errors'),
    isFetching: state.user.get('isFetching'),
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateData: (data) => dispatch(update(data, () => {
      dispatch(flash('Settings updated.'));
    })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
