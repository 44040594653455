import { Map, List } from 'immutable';
import { actions } from 'actions/admin/creatives.js';
import mapper from 'reducers/mapper.js';

const initialState = new Map({
  isFetching: false,
  errors: new List([]),
  data: new Map(),
});

export default function adminCreativesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADMIN_CREATIVE_UPLOAD:
    case actions.ADMIN_CREATIVE_UPDATE:
      return state.merge({ isFetching: true });
    case actions.ADMIN_CREATIVES_FETCH_SUCCESS:
    case actions.ADMIN_CREATIVE_UPLOAD_SUCCESS:
    case actions.ADMIN_CREATIVE_UPDATE_SUCCESS: {
      const newData = mapper(action.body.results.creatives);
      return state.merge({ isFetching: false }).mergeIn(['data'], newData);
    }
    case actions.ADMIN_CREATIVE_UPDATE_FAILURE:
    case actions.ADMIN_CREATIVE_UPLOAD_FAILURE:
      return state.merge({ isFetching: false, errors: action.body.errors });
    default:
      return state;
  }
}
