import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import Dropzone from 'components/form/Dropzone.js';
import Button from 'components/form/ApiButton.js';
import CodeEditor from 'components/form/CodeEditor.js';
import { upload } from 'actions/admin/creatives.js';
import flash from 'actions/flash.js';
import { maybeFetchAdminPartners } from 'actions/admin/partners.js';

const DEFAULT = {
  templateType: 'creative-snippet',
  sortWeight: 0,
};

class CreativeUpload extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.upload = this.upload.bind(this);
    this.onAddFile = this.onAddFile.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.onProgress = this.onProgress.bind(this);

    this.state = {
      attachment: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(maybeFetchAdminPartners());
  }

  onProgress(progress) {
    this.setState({ progress });
  }

  onAddFile(file) {
    this.setState({ attachment: file });
  }

  onRemoveFile() {
    this.setState({ attachment: null });
  }

  upload(event) {
    event.preventDefault();

    const data = {
      name: this.refs.name.value,
      codeSnippet: this.refs.code.refs.code.value,
      description: this.refs.description.value,
      partner: this.refs.partner.value,
      templateType: this.refs.templateType.value,
      sortWeight: Number(this.refs.sortWeight.value),
    };

    this.props.dispatch(upload(data, this.state.attachment, this.onProgress, () => {
      this.props.dispatch(push('/admin/creatives'));
      this.props.dispatch(flash('Creative uploaded.'));
    }));
  }

  render() {
    return (
      <form className="form" onSubmit={ this.upload }>
        <h1>Upload a new creative</h1>

        <Dropzone
          onDrop={ this.onAddFile }
          onRemove={ this.onRemoveFile }
        >
          <p>Drop your files here or click to browse</p>
        </Dropzone>
        { /* TODO: insert error here */ }
        <div className="form__row">
          <label htmlFor="name">Template Type</label>
          <select className="" ref="templateType" defaultValue={ DEFAULT.templateType }>
            <option key="creative-snippet" value="creative-snippet">
              Creative Snippet
            </option>
            <option key="tool-link" value="tool-link">
              Tool link
            </option>
          </select>
        </div>
        <div className="form__row">
          <label htmlFor="name">Sort weight</label>
          <input type="text" id="name" ref="sortWeight" defaultValue={ DEFAULT.sortWeight } />
        </div>

        <div className="form__row">
          <label htmlFor="name">Creative name</label>
          <input type="text" id="name" ref="name" />
        </div>

        <div className="form__row">
          <label htmlFor="code">Code snippet</label>
          <CodeEditor ref="code" code="" />
        </div>

        <div className="form__row">
          <label htmlFor="description">Description</label>
          <textarea id="description" ref="description" style={{ minHeight: '7rem' }} />
        </div>

        <div className="form__row">
          <label htmlFor="partner">Specific partner?</label>
          <select className="" ref="partner">
            <option key="" />
            { this.props.admin.getIn(['partners', 'data']).map(partner => {
              return (
                <option key={ partner.get('id') } value={ partner.get('id') }>
                  { partner.get('fullName') } - { partner.get('trackingName') }
                </option>
              );
            }) }
          </select>
        </div>

        <div className="form__actions">
          <Button
            className="button--small"
            text="Upload"
            active={ false }
            activeText="Uploading..."
          />

          <span className="text--faded">or </span>
          <Link to="/admin/creatives">cancel</Link>
        </div>
      </form>
    );
  }
}

function select(state) {
  return {
    admin: state.admin,
  };
}

export default connect(select)(CreativeUpload);
