import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import Header from '../../components/common/Header.js';
import AgencyPartnership from './AgencyPartnership';
import DefaultDashboard from './DefaultDashboard';

function isAgencyPartner(partnershipType) {
  return partnershipType === 'agency';
}

class Dashboard extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  render() {
    return (
      <DocumentTitle title="Referoo dashboard">
        <div>
          <Header />
          {isAgencyPartner(this.props.user.get('partnershipType')) ? (
            <div className="grid">
              <AgencyPartnership
                location={this.props.location}
                user={this.props.user}
                isAdmin={false}
              />
            </div>
          ) : (
            <DefaultDashboard location={this.props.location} />
          )}
        </div>
      </DocumentTitle>
    );
  }
}

function select(state) {
  return {
    user: state.user,
  };
}

export default connect(select)(Dashboard);
