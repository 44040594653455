import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Header from 'components/common/Header.js';
import DocumentTitle from 'react-document-title';
import { fetch } from 'actions/partners/withdrawals.js';
import WithdrawalsList from 'containers/partners/WithdrawalsList.js';

class PartnerWithdrawals extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    withdrawals: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.dispatch(fetch());
  }

  getWithdrawals() {
    return this.props.withdrawals.get('data');
  }

  render() {
    return (
      <DocumentTitle title="Withdrawal Requests">
        <div>
          <Header />
          <section className="flash">
            <div className="grid">
              <h3>Your withdrawal requests</h3>
              <p>
              </p>
            </div>
          </section>
          <WithdrawalsList
            withdrawals={ this.getWithdrawals() }
            location={ this.props.location }
          />
        </div>
      </DocumentTitle>);
  }
}

function select(state) {
  return {
    withdrawals: state.withdrawals,
    user: state.user,
  };
}

export default connect(select)(PartnerWithdrawals);
