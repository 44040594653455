import keyMirror from 'keymirror';
const CALL_API = Symbol.for('Call API');
export const actions = keyMirror({
  ADMIN_PARTNERS_FETCH: null,
  ADMIN_PARTNERS_FETCH_SUCCESS: null,
  ADMIN_SINGLE_PARTNER_FETCH_SUCCESS: null,
  ADMIN_PARTNERS_FETCH_FAILURE: null,

  ADMIN_PARTNER_INVITE: null,
  ADMIN_PARTNER_INVITE_SUCCESS: null,
  ADMIN_PARTNER_INVITE_FAILURE: null,

  ADMIN_PARTNER_UPDATE: null,
  ADMIN_PARTNER_UPDATE_SUCCESS: null,
  ADMIN_PARTNER_UPDATE_FAILURE: null,

  ADMIN_REINVITE_PARTNER: null,
  ADMIN_REINVITE_PARTNER_SUCCESS: null,
  ADMIN_REINVITE_PARTNER_FAILURE: null,

  ADMIN_GET_CATEGORIES: null,
  ADMIN_GET_CATEGORIES_SUCCESS: null,
  ADMIN_GET_CATEGORIES_FAILURE: null,

  UNBLINK_PARTNER: null,
});

export function maybeFetchAdminPartners(filters = {}) {
  return (dispatch) => {
    if (filters) {
      const activeFilter = filters.active === false ? '?active=false' : '';
      return dispatch({
        [CALL_API]: {
          types: [
            actions.ADMIN_PARTNERS_FETCH,
            actions.ADMIN_PARTNERS_FETCH_SUCCESS,
            actions.ADMIN_PARTNERS_FETCH_FAILURE,
          ],
          endpoint: `/admin/partners${activeFilter}`,
        },
      });
    }

    return null;
  };
}

export function fetchSingle(partnerId) {
  return {
    [CALL_API]: {
      types: [
        actions.ADMIN_PARTNERS_FETCH,
        actions.ADMIN_PARTNERS_FETCH_SUCCESS,
        actions.ADMIN_PARTNERS_FETCH_FAILURE,
      ],
      endpoint: `/admin/partners/${partnerId}`,
    },
  };
}

export function invite(partner, callback) {
  return {
    [CALL_API]: {
      types: [
        actions.ADMIN_PARTNER_INVITE,
        actions.ADMIN_PARTNER_INVITE_SUCCESS,
        actions.ADMIN_PARTNER_INVITE_FAILURE,
      ],
      endpoint: '/admin/partners',
      method: 'POST',
      body: { partner },
      callback,
    },
  };
}

export function putSingle(id, partner, callback) {
  return {
    [CALL_API]: {
      types: [
        actions.ADMIN_PARTNER_UPDATE,
        actions.ADMIN_PARTNER_UPDATE_SUCCESS,
        actions.ADMIN_PARTNER_UPDATE_FAILURE,
      ],
      endpoint: `/admin/partners/${id}`,
      method: 'PUT',
      body: { partner },
      callback,
    },
  };
}

export function reinvite(partnerId) {
  return {
    [CALL_API]: {
      types: [
        actions.ADMIN_REINVITE_PARTNER,
        actions.ADMIN_REINVITE_PARTNER_SUCCESS,
        actions.ADMIN_REINVITE_PARTNER_FAILURE,
      ],
      endpoint: `/admin/invitations/${partnerId}`,
      method: 'PUT',
      body: {},
    },
  };
}

export function getCategories() {
  return {
    [CALL_API]: {
      types: [
        actions.ADMIN_GET_CATEGORIES,
        actions.ADMIN_GET_CATEGORIES_SUCCESS,
        actions.ADMIN_GET_CATEGORIES_FAILURE,
      ],
      endpoint: '/admin/get-partner-categories',
      method: 'GET',
      body: {},
    },
  };
}
