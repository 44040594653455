import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSingle } from 'actions/admin/partners.js';
import EventList from 'containers/admin/EventList.js';
import AgencyPartnership from '../../dashboard/AgencyPartnership';
import Show from './Show';

function isAgencyPartner(partnershipType) {
  return partnershipType === 'agency';
}

class Partner extends Component {
  static propTypes = {
    partners: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  componentWillMount() {
    this.props.dispatch(fetchSingle(this.props.match.params.id));
  }

  render() {
    const partner = this.props.partners.getIn(['data', String(this.props.match.params.id)]);
    if (!partner || !partner.get('performance')) {
      return <h1>Loading ...</h1>;
    }

    return (
      <div>
        { isAgencyPartner(partner.get('partnershipType')) ?
          <div>
            <AgencyPartnership
              location={this.props.location}
              user={partner}
              isAdmin
            />
            {partner.getIn(['performance', 'events']) && <EventList
              location={ this.props.location }
              events={ partner.getIn(['performance', 'events']) }
            />}
          </div> :
          <Show
            location={this.props.location}
            partner={partner}
          />
        }
      </div>
    );
  }
}

function select(state) {
  return {
    partners: state.admin.get('partners'),
  };
}

export default connect(select)(Partner);
