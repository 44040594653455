import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PasswordField from 'components/form/PasswordField.js';
import Button from 'components/form/ApiButton.js';
import FieldError from 'components/form/FieldError.js';
import { confirm } from 'actions/user.js';

const checkmarkIcon = require('../../../img/checkmark.svg');

class SetPassword extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      submitEnabled: false,
    };
  }

  handleChange = (event) => {
    log('-- SetPassword#handleChange: event=', event.target);
    this.checkSubmitEnabled();
  }

  checkSubmitEnabled = () => {
    const email = this.refs.email.value;
    const password = this.refs.password.refs.password.value;
    const agreementAccepted = this.refs.agreement.checked;

    const enabled =
      email.length > 6 &&
      password.length > 6 &&
      agreementAccepted;

    this.setState({ submitEnabled: enabled });
  }

  handleSubmit = (event) => {
    const { submitEnabled } = this.state;

    event.preventDefault();

    if (submitEnabled) {
      const email = this.refs.email.value;
      const password = this.refs.password.refs.password.value;
      const token = this.props.user.get('token');

      this.props.dispatch(confirm({ token, email, password }, () => {
        this.props.dispatch(push('/creatives'));
      }));
    }
  }

  render = () => {
    const user = this.props.user.toJS();
    const { submitEnabled } = this.state;
    const tosLink = 'https://codeable.io/terms-of-service/partner-program-terms-of-service/';
    const privacyPolicyLink = 'https://codeable.io/terms-of-service/privacy-policy-partners/';

    return (
      <form className="grid--breathe text--centered form--auth" onSubmit={ this.handleSubmit }>
        <div className="">
          <img src={ checkmarkIcon }
            className="breathe--bottom"
            alt="Verification successful"
          />
        </div>
        <h4>Codeable Partner Program Account Activation</h4><br />
        <p className="form__explanation text--left">
          You are nearly ready to go!
        </p>
        <p className="form__explanation text--left">
          Please review the Partner Program Terms of Service then check the box to confirm that you agree, and also review the Partner Program Privacy Policy.
        </p>
        <p className="form__explanation text--left">
          Please choose your own password.
        </p>

        <div className="form__row">
          <label htmlFor="email" >Your email:</label>
          <input type="text" id="email" ref="email" defaultValue={ user.email } onChange={ this.handleChange } />
          <FieldError attribute="email" code="invalid" errors={user.errors}>
            Please enter your email.
          </FieldError>
        </div>

        <div className="form__row">
          <label htmlFor="password">Choose a password:</label>
          <PasswordField ref="password" onChange={ this.handleChange } />
          <FieldError attribute="password" code="invalid" errors={user.errors}>
            Please enter a password.
          </FieldError>
        </div>

        <div className="form__row">
          <input
            type="checkbox"
            id="accept_cdbl_agreement"
            ref="agreement"
            onChange={ this.handleChange }
          />
          <label htmlFor="accept_cdbl_agreement">
            <span className="checkbox__span"></span>
            I agree to the <a href={ tosLink } target="_blank" rel="noopener noreferrer">Codeable Partner Program Terms of Service</a>
          </label>
        </div>

        <div className="form__row">
          <Button
            className="button--small"
            text="Activate Account"
            active={ user.isFetching }
            activeText="Activating..."
            disabled={ !submitEnabled }
          />
        </div>

        <div className="cdbl-privacy-notice">
          <ul>
            <li>
            Your data will be used in relation to your participation in the Codeable Partner Program
            </li>
            <li>
            Your data will not be commercially shared with, or used by, any third parties
            </li>
            <li>
            Your data will be managed in Hubspot, the tool we use to manage partner data, and in Referoo, the Codeable-owned system which operates the Partner Program
            </li>
            <li>
            This Partner Program is brought to you by Codeable ApS, a company registered in Denmark
            </li>
            <li>
            Please review our Partner Program <a href={ privacyPolicyLink } title="Partner Privacy Policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> for any additional details
            </li>
          </ul>

        </div>
      </form>
    );
  }
}

function select(state) {
  return { user: state.user };
}

export default connect(select)(SetPassword);
