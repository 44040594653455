import { LOCATION_CHANGE } from 'connected-react-router';
import { FLASH_OPEN } from 'actions/flash.js';
import { GENERIC_ERROR } from 'middleware/api.js';

import { actions as partnerActions } from 'actions/admin/partners.js';

const initialText = '';

export default function flashReducer(text = initialText, action) {
  switch (action.type) {
    case FLASH_OPEN:
      return {
        type: 'text',
        text: action.text,
      };
    // Clear the message when the route changes, because it should only
    // appear on the current page and not the subsequent ones.
    case LOCATION_CHANGE:
      return {};
    case GENERIC_ERROR:
      return {
        type: 'error',
        text: action.message,
      };
    case partnerActions.ADMIN_PARTNER_INVITE_FAILURE:
      return {
        type: 'error',
        text: action.body && action.body.errors && Array.isArray(action.body.errors) && action.body.errors.length ? action.body.errors[0].message : 'Unknown server error',
      };
    case partnerActions.ADMIN_PARTNER_UPDATE_FAILURE:
      return {
        type: 'error',
        text: action.body && Array.isArray(action.body.errors) && action.body.errors[0].message,
      };

    default:
      return text;
  }
}
