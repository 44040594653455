import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import PasswordField from 'components/form/PasswordField.js';
// import FieldError from 'components/form/FieldError.js';
import Button from 'components/form/ApiButton.js';
import { login } from 'actions/user.js';
import { NavLink } from 'react-router-dom';
const closeIcon = require('../../img/modal-close.svg');

const referooLogo = require('../../img/referoo-codeable-vertical-color.svg');

class Login extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const preloadCloseIcon = new Image();
    preloadCloseIcon.src = closeIcon;
  }

  handleSubmit(event) {
    event.preventDefault();
    const { email } = this.refs;
    const password = this.refs.password.refs.password;

    this.props.dispatch(login({
      email: email.value,
      password: password.value,
      callback: () => {
        /* persistState from 'redux-localstorage' only saves state to localstorage when we dispatch an action,
           however when user data comes back from server, the only place we can dispatch another action is here
           in the callback. Then persistState middleware will store user state to localstorage.
           'PERSIST_STORAGE' reducer doesn't exist, so this string is arbitrary
        */
        this.props.dispatch({ type: 'PERSIST_STORAGE' });
      },
    }));
  }

  renderErrors() {
    const errors = this.props.user.get('errors');

    if (errors.size > 0) {
      return (
        <div className="form__error--block">
          <p>
            {typeof errors.get(0) === 'string' ?
              errors.get(0) :
              'It seems there have been some issues with your login. Please try again.'}
          </p>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <DocumentTitle title="Referoo - Sign in">
        <form className="form form--auth" onSubmit={this.handleSubmit}>
          <header className="text--centered form__header">
            <img src={referooLogo} alt="Referoo logo" />
          </header>
          <main className="form__inner">
            {this.renderErrors()}

            <div className="form__row">
              <label htmlFor="email">Your email</label>
              <input type="text" id="email" ref="email" />
            </div>

            <div className="form__row">
              <label htmlFor="password">Password</label>
              <PasswordField ref="password" />
            </div>

            <div className="form__row">
              <Button
                className="button--full button--small"
                text="Sign in"
                active={this.props.user.get('isFetching')}
                activeText="Signing in..."
              />
            </div>
          </main>

          <p className="text--centered">
            <NavLink to="/reset-password" className="text--small">
              Forgot your password?
            </NavLink>
          </p>

          <p className="text--centered">
            <a href="https://codeable.io/affiliates/" target="_blank" rel="noopener noreferrer" className="text--small">
              Not a partner yet? Apply now.
            </a>
          </p>

        </form>

      </DocumentTitle>
    );
  }
}

function select(state) {
  return {
    user: state.user,
  };
}

export default connect(select)(Login);
