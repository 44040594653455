import keyMirror from 'keymirror';
const CALL_API = Symbol.for('Call API');

export const actions = keyMirror({
  USER_LOGIN: null,
  USER_LOGIN_SUCCESS: null,
  USER_LOGIN_FAILURE: null,
  USER_LOGOUT: null,

  USER_WITHDRAW: null,
  USER_WITHDRAW_SUCCESS: null,
  USER_WITHDRAW_FAILURE: null,
  USER_WITHDRAW_DONE: null,

  USER_VERIFY: null,
  USER_VERIFY_SUCCESS: null,
  USER_VERIFY_FAILURE: null,

  USER_CONFIRM: null,
  USER_CONFIRM_SUCCESS: null,
  USER_CONFIRM_FAILURE: null,

  USER_UPDATE: null,
  USER_UPDATE_SUCCESS: null,
  USER_UPDATE_FAILURE: null,

  USER_RESET_PASSWORD: null,
  USER_RESET_PASSWORD_SUCCESS: null,
  USER_RESET_PASSWORD_FAILURE: null,

  USER_UPDATE_PASSWORD: null,
  USER_UPDATE_PASSWORD_SUCCESS: null,
  USER_UPDATE_PASSWORD_FAILURE: null,

  USER_SESSION_VALID: null,
  USER_SESSION_VALID_SUCCESS: null,
  USER_SESSION_VALID_FAILURE: null,

  USER_CLEAR_ERRORS: null,
  USER_ERRORS: null,
});

export function login({ email, password, callback }) {
  return {
    [CALL_API]: {
      types: [
        actions.USER_LOGIN,
        actions.USER_LOGIN_SUCCESS,
        actions.USER_LOGIN_FAILURE,
      ],
      endpoint: '/users/login',
      method: 'POST',
      body: { email, password },
      callback,
    },
  };
}

export function logout() {
  return {
    type: actions.USER_LOGOUT,
  };
}

export function withdraw(withdrawal) {
  return {
    [CALL_API]: {
      types: [
        actions.USER_WITHDRAW,
        actions.USER_WITHDRAW_SUCCESS,
        actions.USER_WITHDRAW_FAILURE,
      ],
      endpoint: '/withdrawals',
      method: 'POST',
      body: {
        withdrawal,
      },
    },
  };
}

export function isSessionStillValid() {
  return {
    [CALL_API]: {
      types: [
        actions.USER_SESSION_VALID,
        actions.USER_SESSION_VALID_SUCCESS,
        actions.USER_SESSION_VALID_FAILURE,
      ],
      endpoint: '/verify-token',
    },
  };
}

export function verify(token) {
  return {
    [CALL_API]: {
      types: [
        actions.USER_VERIFY,
        actions.USER_VERIFY_SUCCESS,
        actions.USER_VERIFY_FAILURE,
      ],
      endpoint: '/user/verify',
      method: 'POST',
      body: { token },
    },
  };
}

export function confirm(user, callback = undefined) {
  return {
    [CALL_API]: {
      types: [
        actions.USER_CONFIRM,
        actions.USER_CONFIRM_SUCCESS,
        actions.USER_CONFIRM_FAILURE,
      ],
      endpoint: '/user/confirm',
      method: 'POST',
      body: { user },
      callback,
    },
  };
}

export function update(user, callback = undefined) {
  return {
    [CALL_API]: {
      types: [
        actions.USER_UPDATE,
        actions.USER_UPDATE_SUCCESS,
        actions.USER_UPDATE_FAILURE,
      ],
      endpoint: '/user/update',
      method: 'PUT',
      body: { user },
      callback,
    },
  };
}

export function resetPassword(user, callback = undefined) {
  return {
    [CALL_API]: {
      types: [
        actions.USER_RESET_PASSWORD,
        actions.USER_RESET_PASSWORD_SUCCESS,
        actions.USER_RESET_PASSWORD_FAILURE,
      ],
      endpoint: '/user/reset-password',
      method: 'POST',
      body: { user },
      callback,
    },
  };
}

export function updatePassword(user, token, callback = undefined) {
  return {
    [CALL_API]: {
      types: [
        actions.USER_UPDATE_PASSWORD,
        actions.USER_UPDATE_PASSWORD_SUCCESS,
        actions.USER_UPDATE_PASSWORD_FAILURE,
      ],
      endpoint: `/user/reset-password?token=${token}`,
      method: 'PUT',
      body: { user },
      callback,
    },
  };
}

export function clearErrors() {
  return {
    type: actions.USER_CLEAR_ERRORS,
    action: {},
  };
}

export function showError(error) {
  return {
    type: actions.USER_ERRORS,
    error,
  };
}

export function withdrawalDone() {
  return {
    type: actions.USER_WITHDRAW_DONE,
  };
}
