import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Table, Thead, Th, Tr, Td } from 'reactable';
import { FormattedDate } from 'react-intl';
import { formatMoney } from 'accounting';
import { isUndefined } from 'lodash';

const formatPaymentDetails = (details) => {
  const provider = details.preferred;
  const account = details[provider];
  let entry = {};
  if (provider.toUpperCase() === 'BANK' && account.country === 'US') {
    entry = {
      bank: 'US BANK ACCOUNT',
      source: account.accountNumber,
      holderName: account.holderName,
    };
  } else if (provider.toUpperCase() === 'BANK' && !isUndefined(account.country)) {
    entry = {
      bank: 'BANK ACCOUNT',
      source: account.iban,
      holderName: account.holderName,
    };
  } else if (provider.toUpperCase() === 'IBAN') {
    entry = {
      bank: 'BANK ACCOUNT',
      source: details.iban,
    };
  } else {
    entry = {
      bank: 'PAYPAL',
      source: details.paypal,
    };
  }
  return entry;
};

export default class PartnerWithdrawalsList extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    withdrawals: PropTypes.object.isRequired,
  };

  requestedAmount(withdrawal) {
    const amount = withdrawal.withdrawalAmount;

    return amount ? formatMoney(amount) : null;
  }

  paymentDetailsError() {
    const error = 'Provider and account not provided.';

    return (
      <ul>
        <li display="none">
          { error }
        </li>
      </ul>
    );
  }

  partnerPaymentDetails(details) {
    const provider = details.preferred;
    const account = details[provider];

    if (provider === undefined || account === undefined) {
      return this.paymentDetailsError();
    }
    const data = formatPaymentDetails(details);
    return (
      <ul>
        <li display="none">
          { data.bank }
        </li>
        <li display="none">
          { data.holderName ? data.holderName : ''}
        </li>
        <li display="none">
          { data.source }
        </li>
      </ul>
    );
  }

  formatDate(date) {
    return (
      <FormattedDate
        value={date}
        day="numeric"
        month="long"
        year="numeric"
        hour="2-digit"
        minute="numeric"
      />
    );
  }

  render() {
    return (
      <div className="grid">
        <Table className="table">
          <Thead>
            <Th column="createdAt">Date</Th>
            <Th column="id">#ID</Th>
            <Th column="amount">Amount</Th>
            <Th column="paymentDetails">Account Details</Th>
            <Th column="cdblNotified">Cdbl Notified</Th>
          </Thead>

          {this.props.withdrawals.valueSeq().map(withdrawal => {
            const w = withdrawal.toJS();
            return (
              <Tr key={ `withdrawal-${w.id}` }>
                <Th column="createdAt" className="td--primary">
                  {this.formatDate(w.createdAt) }
                </Th>

                <Td column="id">{ w.id }</Td>

                <Td column="amount" value={w.withdrawalAmount} className="td--primary">
                  { this.requestedAmount(w) }
                </Td>

                <Th column="paymentDetails" className="td--primary">
                  { this.partnerPaymentDetails(w.paymentDetails) }
                </Th>

                <Th column="cdblNotified">
                  { w.cdblNotified ? 'Yes' : 'No' }
                </Th>
              </Tr>
            );
          })}
        </Table>
      </div>
    );
  }
}
