import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table, Thead, Th, Tr, Td } from 'reactable';
import { Link } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { formatMoney } from 'accounting';
import * as QueryString from 'query-string';

export default class EventList extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    events: PropTypes.object.isRequired,
  };

  codeableTaskLink(ev) {
    return ev.projectId ?
      (<a target="_blank" rel="noopener noreferrer" href={`http://admin.codeable.io/#/tasks/${ev.projectId}`}>
        {ev.projectId}
      </a>) : null;
  }

  projectAmount(ev) {
    return ev.netAmount ? formatMoney(ev.netAmount) : null;
  }

  renderFilterRemoval() {
    const query = QueryString.parse(this.props.location.search);
    const { pathname } = this.props.location;

    if (query.trackingID || query.shortcode || query.name) {
      return (
        <Link style={{ lineHeight: '3rem' }} to={`${pathname}`}>
          remove filter:
          <strong>{query.trackingID || query.shortcode || query.name}</strong>
        </Link>
      );
    }
    return <span />;
  }

  render() {
    const query = QueryString.parse(this.props.location.search);
    const { pathname } = this.props.location;

    return (
      <div>
        <div className="content-header">
          <div className="pull-right">
            {this.renderFilterRemoval()}
          </div>
          <h1>Events</h1>
        </div>
        <Table className="table"
          hideFilterInput
          filterable={ ['trackingId', 'shortcode', 'name'] }
          filterBy={ query.trackingID || query.shortcode || query.name}
        >
          <Thead>
            <Th column="id">ID</Th>
            <Th column="name">Type</Th>
            <Th column="trackingId">Tracking ID</Th>
            <Th column="shortcode">Shortcode</Th>
            <Th column="partnerName">Partner</Th>
            <Th column="creativeName">Creative</Th>
            <Th column="createdAt">Created</Th>
            <Th column="taskId">Task</Th>
            <Th column="projectAmount">Amount</Th>
          </Thead>
          {this.props.events.valueSeq().map(event => {
            const ev = event.toJS();
            return (
              <Tr key={ `event-${ev.id}` }>
                <Td column="id" className="td--primary">{ ev.id }</Td>

                <Td column="name" value={ev.name}>
                  <Link to={`${pathname}?name=${ev.name}`}>
                    {ev.name}
                  </Link>
                </Td>

                <Td column="trackingId" value={ev.trackingId}>
                  <Link to={`${pathname}?trackingID=${ev.trackingId}`}>
                    {ev.trackingId}
                  </Link>
                </Td>
                <Td column="shortcode" className="td--primary" value={ev.shortcode}>
                  <Link to={`${pathname}?shortcode=${ev.shortcode}`}>
                    {ev.shortcode}
                  </Link>
                </Td>
                <Th column="partnerName">
                  <Link to={`/admin/partners/${ev.partnerId}`}>
                    {ev.partnerName}
                  </Link>
                </Th>
                <Th column="creativeName">{ev.creativeName}</Th>
                <Th column="createdAt">
                  <FormattedDate
                    value={ev.createdAt}
                    day="numeric"
                    month="long"
                    year="numeric"
                    hour="2-digit"
                    minute="numeric"
                  />
                </Th>
                <Th column="taskId">
                  { this.codeableTaskLink(ev) }
                </Th>
                <Th column="projectAmount">
                  { this.projectAmount(ev) }
                </Th>
              </Tr>
            );
          })}
        </Table>
      </div>
    );
  }
}
