import keyMirror from 'keymirror';
const CALL_API = Symbol.for('Call API');

export const actions = keyMirror({
  EVENTS_FETCH: null,
  EVENTS_FETCH_SUCCESS: null,
  EVENTS_FETCH_FAILURE: null,
});

export function fetch() {
  return {
    [CALL_API]: {
      types: [
        actions.EVENTS_FETCH,
        actions.EVENTS_FETCH_SUCCESS,
        actions.EVENTS_FETCH_FAILURE,
      ],
      method: 'GET',
      endpoint: '/admin/events',
    },
  };
}
