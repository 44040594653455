import PropTypes from 'prop-types';
import React from 'react';

const ApiButton = ({ active, className, activeText, text, disabled }) => {
  if (active) {
    return (
      <button className={ className } disabled>
        <div className="loader">Loading</div> { activeText }
      </button>
    );
  }

  if (disabled) {
    return (
      <button className={ className } disabled>{ text }</button>
    );
  }

  return (
    <button className={ className }>{ text }</button>
  );
};

ApiButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  activeText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default ApiButton;
