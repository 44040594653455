/* eslint-disable global-require */

import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import persistState from 'redux-localstorage';
import adapter from 'store/immutableStorageAdapter.js';
import filter from 'redux-localstorage-filter';
import apiMiddleware from 'middleware/api.js';
import createRootReducer from '../reducers/index.js';

export const history = createBrowserHistory();

// Mandatory reading before diving into this function:
// https://medium.com/@meagle/understanding-87566abcfb7a#.2lrjovvkk
export default function configureStore() {
  let createStoreWithMiddleware;

  const middleware = applyMiddleware(routerMiddleware(history),
    thunkMiddleware, apiMiddleware);

  const filtered = compose(filter('user'))(adapter(window.localStorage));
  const persistedState = persistState(filtered, `referoo-${process.env.NODE_ENV}`);

  if (process.env.NODE_ENV === 'development') {
    // const devTools = window.devToolsExtension
    //   ? window.devToolsExtension()
    //   : require('./DevTools.js').default.instrument();
    createStoreWithMiddleware = compose(persistedState, middleware);
  } else {
    createStoreWithMiddleware = compose(persistedState, middleware);
  }

  const rootReducer = createRootReducer(history);
  const store = createStoreWithMiddleware(createStore)(rootReducer);

  if (module.hot) {
    module.hot.accept('../reducers/index.js', () => {
      const nextRootReducer = createRootReducer(history);
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
/* eslint-enable global-require */
