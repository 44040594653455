import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney as toMoney } from 'accounting';
import { close } from '../actions/modal.js';
import { clearErrors } from '../actions/user.js';

class WithdrawSuccessModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    balance: PropTypes.number.isRequired,
    pendingAmount: PropTypes.number.isRequired,
    success: PropTypes.bool.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(clearErrors());
  }

  close() {
    this.props.dispatch(close());
  }

  render() {
    return (
      <div className="modal-content">
        <h1>Withdrawal request</h1>
        { this.props.success ? (
          <div>
            <p>Withdrawal request received. Thank you for doing business with us!</p>
            <p>Pending withdrawal: <strong>{ toMoney(this.props.pendingAmount) }</strong></p>
            <p>Your current balance is now <strong>{ toMoney(this.props.balance) }</strong></p>
            <br />
          </div>) : (
          <div>
            <p>Withdrawal request failed!</p>
            <br />
          </div>)
        }
        <div className="form__row">
          <a href="#" className="button button--small" onClick={ this.close.bind(this) }>
            Close
          </a>
        </div>
      </div>
    );
  }
}

export default WithdrawSuccessModal;
