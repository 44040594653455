import partners from 'reducers/admin/partners.js';
import creatives from 'reducers/admin/creatives.js';
import events from 'reducers/admin/events.js';
import withdrawals from 'reducers/admin/withdrawals.js';
import { Map } from 'immutable';

const initialState = new Map({
  creatives: undefined,
  partners: undefined,
  withdrawals: undefined,
});

export default function adminReducer(state = initialState, action) {
  return state.merge({
    partners: partners(state.get('partners'), action),
    creatives: creatives(state.get('creatives'), action),
    events: events(state.get('events'), action),
    withdrawals: withdrawals(state.get('withdrawals'), action),
  });
}
