import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchSingle, putSingle, getCategories } from 'actions/admin/partners.js';
import PartnerForm from 'components/form/PartnerForm.js';
import { push } from 'connected-react-router';

class Edit extends React.Component {
  static propTypes = {
    partners: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(fetchSingle(this.props.match.params.id));
    this.props.dispatch(getCategories());
  }

  handleSubmit(data) {
    this.props.dispatch(putSingle(this.props.match.params.id, data, () => {
      this.props.dispatch(push('/admin/partners'));
    }));
  }

  render() {
    const partner = this.props.partners.getIn(['data', String(this.props.match.params.id)]);
    const isEdit = true;
    return (<PartnerForm
      dispatch={ this.props.dispatch }
      admin={ this.props.admin }
      edit={ isEdit }
      partner={ partner || {} }
      handleSubmit={ this.handleSubmit }
      user={ this.props.user }
    />);
  }
}

function select(state) {
  return {
    partners: state.admin.get('partners'),
    admin: state.admin,
    user: state.user,
  };
}

export default connect(select)(withRouter(Edit));
