import keyMirror from 'keymirror';
import { push } from 'connected-react-router';

export const actions = keyMirror({
  MODAL_OPEN: null,
  MODAL_CLOSE: null,
  MODAL_USER_OPENED: null,
});

export function open(whichModal, getParam = false) {
  return (dispatch) => {
    dispatch({ type: actions.MODAL_USER_OPENED, isOpened: true });
    dispatch({ type: actions.MODAL_OPEN, whichModal });
    if (getParam) {
      dispatch(push({ search: `?modal=${getParam}` }));
    }
  };
}

export function close() {
  return (dispatch) => {
    dispatch({ type: actions.MODAL_CLOSE });
    dispatch({ type: actions.MODAL_USER_OPENED, isOpened: false });
    dispatch(push({ search: '' }));
  };
}
