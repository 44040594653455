import PropTypes from 'prop-types';
import React from 'react';
import { timeDay } from 'd3-time';
import { timeFormat } from 'd3-time-format';

const Grid = (props) => {
  const { xScale, yScale, data, width, height, children, font } = props;

  const format = timeFormat('%b %d');
  const xTicks = xScale.ticks(timeDay.filter((d) => {
    return d.getDay() % 7 === 0;
  })).map(tick => format(tick));
  const yTicks = yScale.ticks(height / 30);

  return (
    <g className="chart-grid">
      {/* vertical lines from the date up */}
      {data.map((datum, index) => {
        const date = new Date(datum.date);
        const formattedDate = format(date);
        const offset = xScale(date);

        if (xTicks.indexOf(formattedDate) === -1) {
          return (
            <g key={ `vertical-label-${index}` }>
              <line
                x1={ offset }
                y1="10"
                x2={ offset }
                y2={ parseInt(height, 10) - 50 }
                stroke="#E3E5E7"
                strokeWidth="0.5"
              />
            </g>
          );
        }

        // Darker line on a tick with label
        return (
          <g key={ `vertical-label-${index}` }>
            <text
              x={parseInt(offset, 10)}
              y={parseInt(height, 10) - 35}
              fontSize="12"
              textAnchor="begin"
              fill="#90989D"
              fontFamily={ font || 'inherit' }
            >
              { formattedDate }
            </text>
            <line
              x1={ offset }
              y1="10"
              x2={ offset }
              y2={parseInt(height, 10) - 50}
              stroke="#E3E5E7"
              strokeWidth="2"
            />
          </g>
        );
      })}

      {/* horizontal lines from the number right */}
      {yTicks.map((value, index) => {
        // console.log('y:', value);
        const offset = yScale(value);
        if (Math.round(value) !== value) return null;
        return (
          <g className="y-labels" key={ `horizontal-label-${index}` }>
            <text
              // value has to be integer!
              x={ Math.round(value) !== value ? -100 : 30 }
              y={ parseInt(offset, 10) + 14 }
              fontSize="12"
              fill="#90989D"
              textAnchor="end"
              fontFamily={ font || 'inherit' }
            >
              { value }
            </text>
            <line
              key={ `horizontal-line-${index}` }
              x1="40"
              y1={ offset + 10 }
              x2={ width }
              y2={ offset + 10 }
              stroke="#E3E5E7"
              strokeWidth="0.5"
            />
          </g>
        );
      })}

      <line
        x1="40"
        y1={height - 50}
        x2={width + 10}
        y2={height - 50}
        stroke="#E3E5E7"
        strokeWidth="2"
      />
      { children }

      { /* Loop through children to build the legend */ }
      { React.Children.map(children, (child, index) => {
        const xPos = (width / 2) + (60 * index);
        return (
          <g>
            <circle
              cx={ xPos }
              cy={ props.height - (props.height / 20) }
              r="2.5"
              fill="#FFFFFF"
              stroke={ child.props.color }
              strokeWidth="2"
              transform="translate(-10, -5)"
            />
            <text
              x={ xPos }
              y={ props.height - (props.height / 20) }
              fontSize="13"
              stroke="none"
              fontFamily={ child.props.font || 'inherit' }
              style={{ fontWeight: '700', textTransform: 'uppercase' }}
              fill={ child.props.color }
            >
              { child.props.label ? child.props.label.toUpperCase() : child.props.type.toUpperCase() }
            </text>
          </g>
        );
      }) }
    </g>
  );
};

Grid.propTypes = {
  font: PropTypes.string,
  data: PropTypes.array,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
};

export default Grid;
