import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetch } from 'actions/admin/events.js';
import EventList from 'containers/admin/EventList.js';

class Events extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    events: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.dispatch(fetch());
  }

  render() {
    return (
      <EventList
        events={ this.props.events.get('data') }
        location={ this.props.location }
      />);
  }
}

function select(state) {
  return {
    events: state.admin.get('events'),
  };
}

export default connect(select)(Events);
