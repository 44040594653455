import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import { modalStyles } from './Modal.js';

const closeIcon = require('../../img/modal-close.svg');

const StaticModal = ({ children, isOpen, closeModal }) => {
  return (
    <Modal isOpen={ isOpen } style={ modalStyles } contentLabel="Modal">
      <img src={ closeIcon }
        alt="Close modal window"
        className="ReactModal__Close"
        onClick={ closeModal }
      />
      { children }
    </Modal>
  );
};

StaticModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  children: PropTypes.object.isRequired,
};

export default StaticModal;
