import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as QueryString from 'query-string';
import { open, close } from '../../actions/modal.js';
import WithdrawModal from '../../modals/Withdraw.js';
import WithdrawSuccessModal from '../../modals/WithdrawSuccess.js';


class WithdrawFunds extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    withdrawButtonStyle: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.openWithdrawModal = this.openWithdrawModal.bind(this);
    this.openWithdrawSuccessModal = this.openWithdrawSuccessModal.bind(this);
  }

  componentWillMount() {
    const query = QueryString.parse(this.props.location.search);
    if (query.modal === 'withdraw') {
      this.openWithdrawModal();
    }
    if (query.modal === 'withdrawSuccess') {
      this.openWithdrawSuccessModal();
    }
  }

  componentWillReceiveProps(props) {
    if (props.user.get('withdrawSuccessful') === true || props.user.get('withdrawSuccessful') === false) {
      this.openWithdrawSuccessModal();
    }
  }

  openWithdrawModal() {
    this.props.dispatch(open(<WithdrawModal user={ this.props.user } />, 'withdraw'));
  }

  openWithdrawSuccessModal() {
    if (this.props.user.get('withdrawSuccessful') || this.props.user.get('withdrawSuccessful') === false) {
      const modal = (<WithdrawSuccessModal
        balance={ parseFloat(this.props.user.get('currentBalance')) }
        dispatch={ this.props.dispatch }
        success={ this.props.user.get('withdrawSuccessful') }
        pendingAmount={ this.props.user.get('pendingAmount') }
      />);
      this.props.dispatch(open(modal, 'withdrawSuccess'));
    } else {
      this.props.dispatch(close());
    }
  }

  render() {
    return (
      <button
        className="button--min"
        style={ this.props.withdrawButtonStyle }
        onClick={ this.openWithdrawModal }
      >Withdraw funds</button>
    );
  }
}

export default connect()(WithdrawFunds);
