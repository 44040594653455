import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import LineChart from 'components/chart/LineChart.js';
import EventList from 'containers/admin/EventList.js';

class Show extends React.Component {
  static propTypes = {
    partner: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,

    location: PropTypes.object.isRequired,
  };

  isReverseReward(plan) {
    const planType = plan.get('planType');

    return planType === 'reverse-reward';
  }

  renderReward(plan) {
    if (plan === undefined) return 'No plan for partner.';

    const rewardType = plan.get('rewardType');
    const rewardAmount = plan.get('rewardAmount');

    if (rewardType === '%') {
      return `${rewardAmount} ${rewardType}`;
    }

    return `${rewardType} ${rewardAmount}`;
  }

  renderRewardDescription(plan) {
    const defaultDescription = 'What you get for each converted visitor';
    const reverseRewardDescription = 'Given to new clients using your link';

    if (this.isReverseReward(plan)) return reverseRewardDescription;

    return defaultDescription;
  }

  renderPlanRewardType(plan) {
    const defaultPlanRewardType = 'reward';
    const couponPlanRewardType = 'coupon';

    if (this.isReverseReward(plan)) return couponPlanRewardType;

    return defaultPlanRewardType;
  }

  render() {
    const partner = this.props.partner;

    // Because we need the full record (with performance data)
    if (!partner || !partner.get('performance').toJS().data) return <div />;

    const performanceData = partner.get('performance').toJS().data || [];

    return (
      <div>
        <header className="content-header">
          <h1>
            Showing performance for { partner.get('fullName') } ({ partner.get('trackingName') })
          </h1>
        </header>

        <section>
          <div className="grid__quarter">
            <p>
              <strong>{ partner.getIn(['performance', 'totalHits']) }</strong>
              <span className="stats__unit"> clicks</span>
            </p>
          </div>
          <div className="grid__quarter">
            <p>
              <strong>{ partner.getIn(['performance', 'totalConversions']) }</strong>
              <span className="stats__unit"> conversions</span>
            </p>
          </div>
          <div className="grid__quarter">
            <p>
              <strong>{ partner.getIn(['performance', 'conversionRate']) }%</strong>
              <span className="stats__unit"> conversion rate</span>
            </p>
          </div>
          <div className="grid__quarter grid__last">
            <p>
              <strong>${ partner.getIn(['performance', 'totalEarnings']) }</strong>
              <span className="stats__unit"> earnings</span>
            </p>
          </div>
        </section>

        <LineChart type="hits" height="250" color="#118DC9" data={ performanceData } />
        <LineChart type="conversions" height="150" color="#F05A28" data={ performanceData } />


        <section className="stats-wrap">
          <div className="stats grid__half">
            <h2 className="stats__header">
              { partner.getIn(['performance', 'totalHits']) } <span className="stats__unit">clicks</span>
            </h2>
            <p>How many times your links have been clicked.</p>
          </div>
          {
            this.isReverseReward(partner.get('plan')) ?
              <div className="stats stats--static grid__half grid__last">
                <h2 className="stats__header">
                  { this.renderReward(partner.get('plan')) }
                  <span className="stats__unit"> { this.renderPlanRewardType(partner.get('plan')) }</span>
                </h2>
                <p>Given to new clients using your link</p>
              </div>
              :
              <div className="stats grid__half grid__last">
                <h2 className="stats__header">
                  ${ partner.getIn(['performance', 'totalEarnings']) } <span className="stats__unit">earnings</span>
                </h2>
                <p>How much you have earned in the selected period.</p>
              </div> }
        </section>

        <section className="stats-wrap">
          <div className="stats grid__half">
            <h2 className="stats__header">
              { partner.getIn(['performance', 'totalConversions']) } <span className="stats__unit">conversions</span>
            </h2>
            <p>How many visitors converted to paying clients.</p>
          </div>
          <div className="stats grid__half grid__last">
            <h2 className="stats__header">
              { partner.getIn(['performance', 'conversionRate']) }% <span className="stats__unit">conversion rate</span>
            </h2>
            <p>What percentage of visitors converted to customers.</p>
          </div>
        </section>
        {
          this.isReverseReward(partner.get('plan')) ? null :
            <section className="stats-wrap">
              <div className="stats stats--static grid__half">
                <h2 className="stats__header">
                  ${ partner.get('currentBalance') }
                  <span className="stats__unit"> current balance</span>
                </h2>
                <p>How much you have earned but not yet withdrawn.</p>
              </div>
              <div className="stats stats--static grid__half grid__last">
                <h2 className="stats__header">
                  { this.renderReward(partner.get('plan')) }
                  <span className="stats__unit"> { this.renderPlanRewardType(partner.get('plan')) }</span>
                </h2>
                <p>{ this.renderRewardDescription(partner.get('plan')) }</p>
              </div>
            </section>
        }

        <EventList
          location={ this.props.location }
          events={ partner.getIn(['performance', 'events']) }
        />

      </div>
    );
  }
}

function select(state) {
  return {
    partners: state.admin.get('partners'),
  };
}

export default connect(select)(Show);
