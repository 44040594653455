import { actions } from 'actions/modal.js';

const initialState = false;

export default function modalReducer(state = initialState, action) {
  if (action.type === actions.MODAL_OPEN) {
    return action.whichModal;
  } else if (action.type === actions.MODAL_CLOSE) {
    return false;
  }
  return state;
}
