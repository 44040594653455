import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const TextField = ({
  label,
  htmlFor,
  value,
  name,
  type,
  errorText,
  fieldClass,
  handleChange,
  handleBlur,
  touched,
  autoComplete,
}) => {
  // eslint-disable-next-line no-console
  return (
    <div className={ fieldClass }>
      <label htmlFor={ htmlFor }>{ label }</label>
      <input
        name={ name }
        id={ htmlFor }
        type={ type }
        value={ value }
        onChange={ handleChange }
        onBlur={ handleBlur }
        className={ cx({ 'input-error': touched && errorText }) }
        autoComplete={ autoComplete }
      />
      { (touched && errorText) && <div className="input-error-message">{ errorText }</div> }
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  fieldClass: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.bool,
  autoComplete: PropTypes.string,
};

export default TextField;
