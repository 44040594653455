import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
import DocumentTitle from 'react-document-title';
import configureStore, { history } from 'store/configureStore.js';
import routes from './routes.js';
import config from 'config';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
const sentryDns = 'https://6cf1e577d2f1400b8a8956090e206010@o26974.ingest.sentry.io/70459';
const REVISION = process.env.REVISION ? process.env.REVISION.substring(0, 7) : '';
Sentry.init({
  debug: false,
  dsn: process.env.NODE_ENV === 'production' ? sentryDns : null,
  release: `referoo-frontend@${REVISION}`,
  environment: config.sentryEnv,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.05,
});
if (process.env.NODE_ENV === 'development') {
  Sentry.setTag('local', 'true');
}
window.log = (msg) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(msg);
  }
};

require('../scss/styles.scss');

// Set up locale formats for showing currencies and percentages (used in showing rewards)
const intlData = {
  locale: 'en',
  formats: {
    number: {
      USD: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      },
      percentage: {
        style: 'percent',
      },
    },
  },
};

// Trigger store configuration and sync it with history
const store = configureStore();

// Open a separate devTools inspector window on development
// if (process.env.NODE_ENV === 'development') {
//   if (!window.devToolsExtension) {
//     require('./store/createDevToolsWindow.js').default(store); // eslint-disable-line
//   } else {
//     window.devToolsExtension.open();
//   }
// }

// The main application function that renders the virtual DOM into document
function run() {
  const node = (
    <IntlProvider {...intlData}>
      <DocumentTitle title="Referoo">
        <Provider store={ store }>
          <ConnectedRouter history={history}>
            { routes }
          </ConnectedRouter>
        </Provider>
      </DocumentTitle>
    </IntlProvider>
  );

  const target = document.getElementById('root');
  ReactDOM.render(node, target, () => {
    if (process.env.NODE_ENV === 'test') {
      window.doneRendering();
    }
  });
}

// If there's no Intl, polyfill it (old browsers + Safari), then run the app
if (!global.Intl) {
  require.ensure(['intl', 'intl/locale-data/jsonp/en.js'], (require) => {
    require('intl');
    require('intl/locale-data/jsonp/en.js');
    run();
  });
} else {
  run();
}
