import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { fetch } from 'actions/admin/withdrawals.js';
import WithdrawalsList from 'containers/admin/WithdrawalsList.js';

class AdminWithdrawals extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    withdrawals: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.dispatch(fetch());
  }

  getWithdrawals() {
    return this.props.withdrawals.get('data');
  }

  render() {
    return (
      <WithdrawalsList
        withdrawals={ this.getWithdrawals() }
        location={ this.props.location }
      />);
  }
}

function select(state) {
  return {
    withdrawals: state.admin.get('withdrawals'),
  };
}

export default connect(select)(AdminWithdrawals);
