import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-solarizedlight.css';

export default class CodeSnippet extends Component {
  constructor(props) {
    super(props);
    this.code = Prism.highlight(this.props.code, this.props.language);
  }

  render() {
    return (
      <pre className="code-snippet">
        <code className="language-" dangerouslySetInnerHTML={{ __html: this.code }} />
      </pre>
    );
  }
}

CodeSnippet.defaultProps = {
  language: Prism.languages.markup,
  code: '',
};

CodeSnippet.propTypes = {
  language: PropTypes.object,
  code: PropTypes.string.isRequired,
};
