import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from 'reducers/user.js';
import creatives from 'reducers/creatives.js';
import modal from 'reducers/modal.js';
import datepickerRange from 'reducers/datepickerRange.js';
import flash from 'reducers/flash.js';
import admin from 'reducers/admin/admin.js';
import partnerWithdrawals from 'reducers/partners/withdrawals.js';

/**
 * Exporting all reducers combined. For hot-reloading support, make sure to
 * require this file with .default ("require('reducers/index.js').default;")
 * otherwise the reducers won't be swapped properly!
 */
export default (history) => combineReducers({
  router: connectRouter(history),
  user,
  admin,
  creatives,
  modal,
  flash,
  withdrawals: partnerWithdrawals,
  datepickerRange,
});
