import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import onClickOutside from 'react-onclickoutside';
import Calendar from 'react-calendar';
import { fetch as fetchPerformance } from 'actions/performance.js';
import { changeDatepickerRange } from 'actions/datepickerRange.js';
import 'react-calendar/dist/Calendar.css';

const datePickerStyle = {
  position: 'relative',
  float: 'right',
  height: '0.01px',
};

function ymd(momentObj) {
  return moment(momentObj).format('YYYY-MM-DD');
}

class Datepicker extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    clickHandler: PropTypes.func.isRequired,
    datepickerRange: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.toggleDialog = props.clickHandler.bind(this);
    this.toggleRange = this.toggleRange.bind(this);
    const dates = props.user.get('performance').get('data').toJS().map(event => event.date).sort();
    const fromDate = dates[0];
    const toDate = dates[dates.length - 1];

    this.state = { range: [moment(fromDate), moment(toDate)] };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.refs.daterange) {
        this.refs.daterange.setState({
          range: this.state.range,
          viewDate: this.state.range[0].unix() * 1000,
        });
        this.props.dispatch(changeDatepickerRange(this.refs.daterange.value));
      }
    }, 0);
  }

  handleClickOutside() {
    this.toggleDialog();
  }

  toggleRange() {
    /* only fetch performance data if we changed range */
    if (this.refs.daterange && Array.isArray(this.refs.daterange.value) && this.refs.daterange.value.length > 1) {
      const start = ymd(this.refs.daterange.value[0]);
      const end = ymd(this.refs.daterange.value[1]);
      if (ymd(this.state.range[0]) !== start || ymd(this.state.range[1]) !== end) {
        this.props.dispatch(fetchPerformance([start, end]));
      }
      if (ymd(this.state.range[0]) !== start || ymd(this.state.range[1]) !== end) {
        this.props.dispatch(fetchPerformance([start, end]));
      }
      this.setState({ range: this.refs.daterange.value });
    }
    this.forceUpdate();
    setTimeout(() => {
      if (this.refs.daterange) {
        this.refs.daterange.setState({
          range: this.state.range,
          viewDate: moment.unix(this.state.range[0]) * 1000,
        });
        this.props.dispatch(changeDatepickerRange(this.refs.daterange.value));
        this.toggleDialog();
      }
    }, 0);
  }

  render() {
    this.dateDialog = (
      <div className="react-date-picker-wrapper">
        <Calendar
          ref="daterange"
          selectRange
          showDoubleView
          tileClassName="calendar-btn"
          defaultValue={this.state.range}
        />
        <div className="react-date-picker-footer">
          <button className="react-date-picker-footer__btn-cancel" onClick={ this.toggleDialog }>Cancel</button>
          <button className="react-date-picker-footer__btn-apply" onClick={ this.toggleRange }>Apply</button>
        </div>
      </div>
    );
    return (
      <div>
        <div style={datePickerStyle}>{this.dateDialog}</div>
      </div>
    );
  }
}

function select(state) {
  return {
    user: state.user,
    datepickerRange: state.datepickerRange,
  };
}

export default connect(select)(onClickOutside(Datepicker));
