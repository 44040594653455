import React from 'react';
// import LineChart from 'components/chart/LineChart.js';

const Admin = () => {
  return (
    <div>
      <header className="content-header">
        <h1>Partners performance</h1>
      </header>

    </div>
  );
};

export default Admin;
