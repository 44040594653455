import PropTypes from 'prop-types';
import React from 'react';

export default class PasswordField extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.toggleType = this.toggleType.bind(this);
    this.onChange = props.onChange;

    this.state = {
      inputType: 'password',
    };
  }

  toggleType() {
    if (this.state.inputType === 'password') {
      this.setState({ inputType: 'text' });
    } else {
      this.setState({ inputType: 'password' });
    }
  }

  render() {
    return (
      <div className="password-input">
        <input type={ this.state.inputType } id="password" ref="password" onChange={ this.onChange } />
        <span className="password-input__toggle" onClick={ this.toggleType }>
          { this.state.inputType === 'password' ? 'show' : 'hide' }
        </span>
      </div>
    );
  }
}
