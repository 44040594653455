import PropTypes from 'prop-types';
import React from 'react';
import Path from './Path.js';

const DataSeries = (props) => {
  const { xScale, yScale, data, show, color } = props;

  function getPoints(key) {
    return data.map(datum => {
      return {
        x: xScale(new Date(datum.date)),
        y: yScale(datum[key]),
        value: datum[key],
      };
    });
  }

  return <Path points={ getPoints(show) } color={ color } />;
};

DataSeries.propTypes = {
  data: PropTypes.array,
  show: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
};

DataSeries.defaultProps = {
  data: [],
  show: 'hits',
};

export default DataSeries;
