import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Header from 'components/common/Header.js';
import Sidebar from 'components/common/Sidebar.js';

class Account extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div>
        <Header />
        <div className="grid">
          <Sidebar />
          <main className="content">
            { this.props.children }
          </main>
        </div>
      </div>
    );
  }
}

function select(state) {
  return {
    user: state.user,
  };
}

export default connect(select)(Account);
