import keyMirror from 'keymirror';
const CALL_API = Symbol.for('Call API');

export const actions = keyMirror({
  ADMIN_CREATIVES_FETCH: null,
  ADMIN_CREATIVES_FETCH_SUCCESS: null,
  ADMIN_CREATIVES_FETCH_FAILURE: null,

  ADMIN_CREATIVE_UPLOAD: null,
  ADMIN_CREATIVE_UPLOAD_SUCCESS: null,
  ADMIN_CREATIVE_UPLOAD_FAILURE: null,

  ADMIN_CREATIVE_UPDATE: null,
  ADMIN_CREATIVE_UPDATE_SUCCESS: null,
  ADMIN_CREATIVE_UPDATE_FAILURE: null,
});

function shouldFetchAdminCreatives(state) {
  if (state.admin.getIn(['creatives', 'data']).size === 0) {
    return true;
  }

  return false;
}

export function maybeFetchAdminCreatives() {
  return (dispatch, getState) => {
    if (shouldFetchAdminCreatives(getState())) {
      return dispatch({
        [CALL_API]: {
          types: [
            actions.ADMIN_CREATIVES_FETCH,
            actions.ADMIN_CREATIVES_FETCH_SUCCESS,
            actions.ADMIN_CREATIVES_FETCH_FAILURE,
          ],
          endpoint: '/admin/creatives',
        },
      });
    }

    return null;
  };
}

export function upload(body, file, onProgress, callback) {
  return {
    [CALL_API]: {
      types: [
        actions.ADMIN_CREATIVE_UPLOAD,
        actions.ADMIN_CREATIVE_UPLOAD_SUCCESS,
        actions.ADMIN_CREATIVE_UPLOAD_FAILURE,
      ],
      endpoint: '/admin/creatives',
      method: 'POST',
      body,
      file,
      callback,
      onProgress,
    },
  };
}

export function update(creative, file, onProgress, callback) {
  return {
    [CALL_API]: {
      types: [
        actions.ADMIN_CREATIVE_UPDATE,
        actions.ADMIN_CREATIVE_UPDATE_SUCCESS,
        actions.ADMIN_CREATIVE_UPDATE_FAILURE,
      ],
      endpoint: `/admin/creatives/${creative.id}`,
      method: 'PUT',
      body: creative,
      file,
      onProgress,
      callback,
    },
  };
}
