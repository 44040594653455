import { actionTypes } from 'redux-localstorage';
import { actions } from 'actions/user.js';
import { actions as modalActions } from 'actions/modal.js';
import { PERFORMANCE_FETCH, PERFORMANCE_FETCH_SUCCESS, PERFORMANCE_FETCH_FAILURE } from 'actions/performance.js';
import { Map, List } from 'immutable';
import { REQUEST_TIMEOUT, TOKEN_NOT_VALID } from 'middleware/api.js';

// There are some defaults missing here, namely the ones our user object gets
// when logging in (such as fullName, email, etc...)
export const initialState = new Map({
  isFetching: false,
  authToken: null,
  user_hash: null,
  errors: new List([]),
  withdrawSuccessful: null,
});

let tempState;

export default function userReducer(state = initialState, action) {
  let temp = state;
  let payload;
  switch (action.type) {
    case 'redux-localstorage/INIT':
      /* get user settings from localStorage and merge them in state (doesn't logs you out on browser refresh) */
      tempState = {};
      if (localStorage.getItem(`referoo-${process.env.NODE_ENV}`)) {
        tempState = state.merge(JSON.parse(localStorage.getItem(`referoo-${process.env.NODE_ENV}`)));
      }
      /* Make sure we reset request numbers at load time */
      return state.merge(tempState, { madeRequests: 0, doneRequests: 0, checkedRequests: 0, isFetching: false });
    case REQUEST_TIMEOUT:
      if (action.httpRequestDone) {
        /* When request comes back from server, close timeout modal */
        temp = state.merge({ doneRequests: (state.get('doneRequests') || 0) + 1, httpTimeout: null });
      }
      if (action.httpRequest) {
        temp = state.merge({ madeRequests: (state.get('madeRequests') || 0) + 1 });
      }
      if (action.httpRequestChecked) {
        temp = state.merge({ checkedRequests: (state.get('checkedRequests') || 0) + 1, httpTimeout: action.timeout });
      }
      return temp;
    case actionTypes.INIT: {
      // TODO: this is a side effect, investigate better ways to do this.
      window.Intercom('update', action.payload); //eslint-disable-line
      return state.mergeDeep(action.payload, { isFetching: false });
    }
    case actions.USER_LOGOUT:
    case actions.USER_RESET_PASSWORD_SUCCESS:
      return initialState;
    case TOKEN_NOT_VALID:
      return initialState.merge({ errors: new List(['Your session has expired, please sign-in.']) });
    case actions.USER_RESET_PASSWORD:
    case actions.USER_LOGIN:
    case actions.USER_WITHDRAW:
    case actions.USER_VERIFY:
    case actions.USER_CONFIRM:
    case actions.USER_UPDATE:
      return state.merge({ isFetching: true });
    case actions.USER_LOGIN_SUCCESS:
    case actions.USER_UPDATE_SUCCESS:
    case actions.USER_VERIFY_SUCCESS:
    case actions.USER_CONFIRM_SUCCESS:
    case actions.USER_UPDATE_PASSWORD_SUCCESS: {
      payload = action.body.results.user;
      payload.user_hash = payload.userHash;
      payload.user_email = payload.email;
      payload.app_id = process.env.INTERCOM_APP_ID;
      window.Intercom('update', payload); //eslint-disable-line
      return state.mergeDeep(payload, { isFetching: false, errors: new List([]) });
    }
    case actions.USER_WITHDRAW_SUCCESS:
      return state.merge({
        withdrawSuccessful: true,
        errors: new List([]),
        currentBalance: action.body.endingBalance,
        pendingAmount: action.body.withdrawalAmount,
        isFetching: false,
      });
    case actions.USER_WITHDRAW_FAILURE:
      return state.merge({ withdrawSuccessful: false, errors: new List([]) });
    case actions.USER_WITHDRAW_DONE: {
      return state.merge({ withdrawSuccessful: null, errors: new List([]) });
    }
    case actions.USER_SESSION_VALID_FAILURE:
      return state.merge({ isFetching: false, logout: true });
    case actions.USER_LOGIN_FAILURE:
    case actions.USER_UPDATE_FAILURE:
    case actions.USER_RESET_PASSWORD_FAILURE:
    case actions.USER_UPDATE_PASSWORD_FAILURE:
      return state.merge({ isFetching: false, errors: new List(action.body.errors) });
    case actions.USER_VERIFY_FAILURE:
    case actions.USER_CONFIRM_FAILURE:
      return state.merge({ isFetching: false, errors: new List(action.body.errors) });
    case actions.USER_CLEAR_ERRORS:
      return state.merge({ withdrawSuccessful: null, errors: new List([]) });
    case actions.USER_ERRORS:
      return state.get('errors').filter(error => action.error.code === error.code).size ? state : state.merge({ errors: new List(state.get('errors').concat(action.error)) });
    case PERFORMANCE_FETCH:
      return state.merge({ isFetching: true });
    case PERFORMANCE_FETCH_SUCCESS:
      return state.merge({ isFetching: false, performance: action.body.results.performance });
    case PERFORMANCE_FETCH_FAILURE:
      return state.merge({ isFetching: false });
    case modalActions.MODAL_USER_OPENED:
      return state.merge({ modalOpened: action.isOpened });
    default:
      return state;
  }
}
