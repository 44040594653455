/**
 * converts an array of objects (which have an id key on them) to an object
 * of objects, with id as the key and the whole original object as the value
 * @param  {Array} the array of objects
 * @return {Object} object with id keys
 */
export default function mapper(array) {
  return array.reduce((prev, curr) => {
    const obj = prev;
    obj[curr.id] = curr;
    return obj;
  }, {});
}
