import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Flash from 'components/common/Flash.js';

const referooLogo = require('../../../img/referoo-codeable-horizontal-color.svg');
const referooLogoMobile = require('../../../img/referoo-codeable-color.svg');

class Header extends React.Component {
  static propTypes = {
    flash: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  // TODO: make sure to check if user is an admin.
  renderAdminLink() {
    const user = this.props.user.toJS();
    if (user.role === 'admin') {
      return (
        <li className="navigation__item">
          <NavLink
            className="navigation__link"
            activeClassName="navigation__link--active"
            to="/admin"
          >
            Admin
          </NavLink>
        </li>
      );
    }

    return <span />;
  }

  renderPartnerWithdrawlsLink() {
    const user = this.props.user.toJS();
    if (user.role === 'partner') {
      return (
        <li className="navigation__item">
          <NavLink to="/withdrawals"
            className="navigation__link"
            activeClassName="navigation__link--active"
          >
            Withdrawals
          </NavLink>
        </li>
      );
    }

    return <span />;
  }


  renderFlash() {
    if (Object.keys(this.props.flash).length) {
      return <Flash obj={this.props.flash} />;
    }
    return <span />;
  }

  render() {
    return (
      <div>
        <header className="header">
          <div className="grid">
            <NavLink to="/">
              <img
                src={referooLogo}
                alt="Referoo logo"
                className="header__logo wide"
              />

              <img
                src={referooLogoMobile}
                alt="Referoo logo"
                className="header__logo narrow"
              />
            </NavLink>
            <nav className="navigation">
              <ul className="navigation__list">
                {this.renderAdminLink()}
                <li className="navigation__item">
                  <NavLink
                    isActive={ (location) => {
                      if (!location) return false;
                      const { pathname } = location;
                      return pathname === '/';
                    }} to="/"
                    className="navigation__link"
                    activeClassName="navigation__link--active"
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li className="navigation__item">
                  <NavLink to="/creatives"
                    className="navigation__link"
                    activeClassName="navigation__link--active"
                  >
                    Creatives
                  </NavLink>
                </li>
                {this.renderPartnerWithdrawlsLink()}
                <li className="navigation__item">
                  <NavLink to="/settings"
                    className="navigation__link"
                    activeClassName="navigation__link--active"
                  >Settings</NavLink>
                </li>
                <li className="navigation__item">
                  <NavLink to="/logout"
                    className="navigation__link"
                    activeClassName="navigation__link--active"
                  >Logout</NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        {this.renderFlash()}
      </div>
    );
  }
}

function select(state) {
  return {
    flash: state.flash,
    user: state.user,
  };
}

export default connect(select)(Header);
