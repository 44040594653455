import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { close } from 'actions/modal.js';

const closeIcon = require('../../img/modal-close.svg');
export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(19, 57, 81, 0.85)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    width: '600px',
    padding: '20px 40px',
  },
};

class ModalWrapper extends React.Component {
  static displayName = 'ModalWrapper';

  static propTypes = {
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func.isRequired,
    modal: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]).isRequired,
  };

  closeModal() {
    this.props.dispatch(close());
  }

  render() {
    return (
      <div className="modal-wrapper">
        <Modal
          isOpen={ !!this.props.modal }
          onRequestClose={ this.closeModal.bind(this) }
          style={ modalStyles }
          contentLabel="Modal"
        >
          <img src={ closeIcon }
            alt="Close modal window"
            className="ReactModal__Close"
            onClick={ this.closeModal.bind(this) }
          />
          { this.props.modal }
        </Modal>

        { this.props.children }
      </div>
    );
  }
}

function select(state) {
  return {
    modal: state.modal,
  };
}

export default connect(select)(ModalWrapper);
