import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { logout } from 'actions/user.js';

class Logout extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(logout());
  }

  componentWillUpdate(props) {
    if (!props.user.authToken) {
      this.props.dispatch(push('/'));
    }
  }

  render() {
    return null;
  }
}

function select(state) {
  return {
    user: state.user,
  };
}

export default connect(select)(Logout);
