import superagent from 'superagent';
import { merge } from 'lodash';
import { camelizeKeys, decamelizeKeys } from 'humps';
import config from 'config';
// Temporary fix until s/b fixes headers
superagent.parse['application/vnd.referoo.v2+json'] = JSON.parse;

const CALL_API = Symbol.for('Call API');
export const GENERIC_ERROR = 'GENERIC_ERROR';
export const REQUEST_TIMEOUT = 'REQUEST_TIMEOUT';
export const TOKEN_NOT_VALID = 'TOKEN_NOT_VALID';
export const REQUEST_TIMEOUT_DURATION = 15000;

const genericErrors = {
  400: 'You\'ve attempted to make a request the server didn\'t expect',
  401: 'You\'re not authenticated within the system, please sign-in.',
  403: 'You\'re not authorized to perform this action',
  404: 'The resource you\'re trying to fetch or modify does not exist',
  500: 'An error occured on the server (not your fault). Admin has been contacted.',
};

/**
 * Prepare headers for each request and include the token for authentication.
 * @param  {Boolean} token Authentication token
 * @return {Object}        Request headers
 */
function setRequestHeaders(authToken) {
  const headers = {
    Accept: 'application/vnd.referoo.v2+json',
  };

  if (authToken) headers.Authorization = `Bearer ${authToken}`;
  return headers;
}

/**
 * Check the state whether we have an auth token present
 * @param  {Object}       Redux store
 * @return {String}       Authentication Token
 */
function getToken(store) {
  const user = store.getState().user.toJS();
  return user.authToken || null;
}

/**
 * Api middleware to make async requests to the server(s). This is the only place
 * in the app where XHR should be made. It automatically "catches" every action
 * with CALL_API symbol and extracts it's data to build a proper request.
 * If a callback is provided in the action, then it may update the calling
 * component with request (upload) progress.
 */
export default store => next => action => {
  const request = action[CALL_API];

  // Ignore the action (pass it on) if it's not meant to make an API request
  if (typeof request === 'undefined') return next(action);

  /**
   * Create a new action (because original should be immutable) and dispatch it
   * into reducers. It's unnecessary to send request info, so we remove it.
   * @param  {Object} data Incoming JSON payload (from the API)
   * @return {Object}      Data for reducers
   */
  function actionWith(newAction) {
    const finalAction = merge({}, action, camelizeKeys(newAction));
    delete finalAction[CALL_API];
    return finalAction;
  }

  // Set defaults via destructuring
  const [requestType, successType, failureType] = request.types;
  const { method = 'GET', onProgress = () => { }, callback = () => { } } = request;
  const body = decamelizeKeys(request.body);

  // Dispatch a "loading" action (useful for showing spinners)
  next(actionWith({ type: requestType }));
  setTimeout(() => {
    next(actionWith({ type: REQUEST_TIMEOUT, httpRequest: true }));
  }, 0);

  /* After REQUEST_TIMEOUT_DURATION seconds after we send http request to server, check if the response
     came back in the meantime */
  // setTimeout(() => {
  //   const doneRequests = store.getState().user.get('doneRequests');
  //   const checkedRequests = store.getState().user.get('checkedRequests') || 0;
  //   next({
  //     type: REQUEST_TIMEOUT,
  //     httpRequestChecked: true,
  //     timeout: doneRequests <= checkedRequests,
  //   });
  // }, REQUEST_TIMEOUT_DURATION);

  /**
   * Completes the response from superagent.
   * Note if you're unable to parse the results (this method with respond with
   * something like "Parser is unable to parse response (...)") this means the
   * returned JSON object isn't valid.
   * @param  {String} err      Any possible errors, null if none
   * @param  {Object} response The response object, generated by underlying XHR.
   */
  function completeResponse(err, response) {
    if (!err) {
      setTimeout(() => {
        next({ type: REQUEST_TIMEOUT, httpRequestDone: true });
      }, 0);
    }

    if (response && response.ok) {
      next(actionWith({ type: successType, body: response.body }));
      callback();
    }

    if (err && err.status === 401) {
      next(actionWith({ type: TOKEN_NOT_VALID, message: genericErrors[err.status] }));
    }

    if (err && genericErrors[err.status] && err.status !== 401) {
      next(actionWith({ type: GENERIC_ERROR, message: genericErrors[err.status] }));
    }

    if (response && !response.ok) {
      next(actionWith({ type: failureType, body: response.body }));
    }
  }

  const preparedRequest = superagent(method, config.apiUrl + request.endpoint)
    .withCredentials()
    .set(setRequestHeaders(getToken(store)));

  /**
   * If the request has 'files' key on it, the request becomes multipart instead
   * of JSON, which means we have to switch from regular .send() to a series
   * of .field(name, value) calls (hence the loop). Superagent creates FormData
   * behind the scenes, so we don't have to.
   */
  if (request.file) {
    preparedRequest.on('progress', event => onProgress(event));

    const fileAttachKey = 'file';
    preparedRequest.attach(fileAttachKey, request.file);

    // FIXME: make sure to remove the eslint-disable lines and fix the error!
    /* eslint-disable */
    for (const property in body) {
      if (body.hasOwnProperty(property)) {
        preparedRequest.field(property, body[property]);
      }
    }
    /* eslint-enable */
  } else {
    preparedRequest.send(body);
  }

  preparedRequest.end(completeResponse);

  return null; // TODO: check whether we can return null or if it even matters.
};
