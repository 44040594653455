import PropTypes from 'prop-types';
import React from 'react';
import config from 'config';

export default class Dropzone extends React.Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
    onDrop: PropTypes.func,
    onRemove: PropTypes.func,
    asset: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.removeImage = this.removeImage.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);

    this.allowedMimeTypes = ['image/png', 'image/jpeg', 'image/gif'];
    this.state = {
      src: this.props.asset ? `${config.apiUrl}${this.props.asset.get('self')}` : '',
      isDragActive: false,
      error: '',
    };
  }

  // We need to have this, otherwise Chrome will redirect to the dropped file.
  onDragOver(event) {
    event.preventDefault();
    this.setState({ isDragActive: true });
  }

  onDragLeave(event) {
    event.preventDefault();
    this.setState({ isDragActive: false });
  }

  onDrop(event) {
    event.preventDefault();
    this.setState({ isDragActive: false, error: '' }); // clear the previous err

    // Get just the first file, we don't support multiple uploads for now.
    const droppedFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    const file = droppedFiles.item(0);

    if (this.allowedMimeTypes.indexOf(file.type) === -1) {
      this.setState({ error: 'Provided file is not an image' });
      return;
    }

    if (this.props.onDrop) this.props.onDrop(file);
    this.createPreview(file);
  }

  onClick() {
    this.refs.fileInput.click();
  }

  removeImage(event) {
    event.preventDefault();
    this.props.onRemove();
    this.setState({ src: '' });
  }

  createPreview(file) {
    const reader = new FileReader();
    reader.onload = () => this.setState({ src: reader.result });
    reader.readAsDataURL(file);
  }

  renderPreview() {
    if (this.state.src) {
      return (
        <div className="form__row">
          <label>Preview
          <span
            className="form__error"
            onClick={ this.removeImage }
          >(click here to remove)</span>
          </label>
          <img src={ this.state.src } alt="" style={{ maxWidth: '520px' }} />
        </div>
      );
    }
    return <span />;
  }

  render() {
    let error = <span />;

    if (this.state.src) {
      return this.renderPreview();
    }

    if (this.state.error) {
      error = <span className="form__error">{ this.state.error }</span>;
    }

    return (
      <div>
        <div
          className="form__dropzone"
          onClick={ this.onClick }
          onDrop={ this.onDrop }
          onDragOver={ this.onDragOver }
          onDragLeave={ this.onDragLeave }
          style={ this.state.isDragActive ? { borderColor: '#39596E' } : {}}
        >
          <input
            style={{ display: 'none' }}
            type="file"
            ref="fileInput"
            onChange={ this.onDrop }
          />
          { this.props.children }
        </div>

        { error }
      </div>
    );
  }
}
