import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Dropzone from 'components/form/Dropzone.js';
import Button from 'components/form/ApiButton.js';
import CodeEditor from 'components/form/CodeEditor.js';
import flash from 'actions/flash.js';
import { update, maybeFetchAdminCreatives } from 'actions/admin/creatives.js';

class CreativeEdit extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.upload = this.upload.bind(this);
    this.onAddFile = this.onAddFile.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.onProgress = this.onProgress.bind(this);

    this.id = parseInt(this.props.match.params.id, 10);

    this.state = {
      attachment: null,
    };
  }

  componentWillMount() {
    this.props.dispatch(maybeFetchAdminCreatives());
  }

  onProgress(progress) {
    this.setState({ progress });
  }

  onAddFile(file) {
    this.setState({ attachment: file });
  }

  onRemoveFile() {
    this.setState({ attachment: null });
  }


  upload(event) {
    event.preventDefault();

    const data = {
      id: this.id,
      name: this.refs.name.value,
      codeSnippet: this.refs.code.refs.code.value,
      description: this.refs.description.value,
      templateType: this.refs.templateType.value,
      sortWeight: Number(this.refs.sortWeight.value),
    };

    this.props.dispatch(update(data, this.state.attachment, this.onProgress, () => {
      this.props.dispatch(flash('Creative updated.'));
    }));
  }

  render() {
    const isFetching = this.props.admin.getIn(['creatives', 'isFetching']);
    const creative = this.props.admin.getIn(['creatives', 'data']).find(singleCreative => {
      return singleCreative.get('id') === this.id;
    });

    if (!creative) {
      return <span />;
    }

    return (
      <form className="form" onSubmit={ this.upload }>
        <h1>Upload a new creative</h1>

        <Dropzone
          onDrop={ this.onAddFile }
          onRemove={ this.onRemoveFile }
          asset={ creative.get('asset') }
        >
          <p>Drop your files here or click to browse</p>
        </Dropzone>
        { /* TODO: insert error here */ }
        <div className="form__row">
          <label htmlFor="name">Template Type</label>
          <select className="" ref="templateType" defaultValue={ creative.get('templateType') }>
            <option key="creative-snippet" value="creative-snippet">
              Creative Snippet
            </option>
            <option key="tool-link" value="tool-link">
              Tool link
            </option>
          </select>
        </div>
        <div className="form__row">
          <label htmlFor="name">Sort weight</label>
          <input type="text" id="name" ref="sortWeight" defaultValue={ creative.get('sortWeight') } />
        </div>

        <div className="form__row">
          <label htmlFor="name">Creative name</label>
          <input type="text" id="name" ref="name" defaultValue={ creative.get('name') } />
        </div>

        <div className="form__row">
          <label htmlFor="code">Code snippet</label>
          <CodeEditor ref="code" code={ creative.get('codeSnippet') } />
        </div>

        <div className="form__row">
          <label htmlFor="description">Description</label>
          <textarea id="description"
            ref="description"
            defaultValue={ creative.get('description') }
            style={{ minHeight: '7rem' }}
          />
        </div>

        <div className="form__actions">
          <Button
            className="button--small"
            text="Update"
            active={ isFetching }
            activeText="Updating..."
          />

          <span className="text--faded">or </span>
          <Link to="/admin/creatives">cancel</Link>
        </div>
      </form>
    );
  }
}

function select(state) {
  return {
    admin: state.admin,
  };
}

export default connect(select)(withRouter(CreativeEdit));
