import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Table, Thead, Th, Tr, Td } from 'reactable';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { maybeFetchAdminPartners } from 'actions/admin/partners.js';
import cx from 'classnames';
import ReactSelect from 'react-select';
// import 'react-select/dist/react-select.css';
import { actions } from '../../../actions/admin/partners.js';
import editIcon from '../../../../img/edit-pencil.svg';

const activePartnersStyle = {
  lineHeight: '40px',
  marginRight: '10px',
};

const stateOptions = [
  { value: 'invited', label: 'Invited' },
  { value: 'confirmed', label: 'Confirmed' },
  { value: 'embedded', label: 'Embedded' },
  { value: 'converted', label: 'Converted' },
];


class PartnersComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    partners: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.radioActive = this.radioActive.bind(this);
    this.radioInactive = this.radioInactive.bind(this);
    this.filter = this.filter.bind(this);
    this.selectState = this.selectState.bind(this);
    this.state = {
      filterText: '',
      activeFilter: true,
      state: stateOptions.filter(x => x.value !== 'invited'),
    };
  }

  componentWillMount() {
    this.props.dispatch(maybeFetchAdminPartners());
  }

  componentDidMount() {
    this.refs['partners-active'].checked = true;
  }

  getRewardValue(plan, type) {
    return plan.get('rewardType') !== type ? null : plan.get('rewardAmount');
  }

  filter(event) {
    event.preventDefault();
    this.setState({ filterText: event.target.value });
  }

  unblinkPartner() {
    /* wait 1s before hiding tr's background */
    setTimeout(() => {
      this.props.dispatch({ type: actions.UNBLINK_PARTNER });
    }, 1000);
  }

  radioActive() {
    this.setState({
      activeFilter: true,
    });
    if (this.state.state[0] && this.state.state[0].value === 'invited') {
      this.setState({
        state: stateOptions.filter(x => x.value !== 'invited'),
      });
    }
  }

  radioInactive() {
    this.setState({
      activeFilter: false,
    });
    if (this.state.state.map(x => x.value).indexOf('invited') === -1) {
      this.setState({
        state: [stateOptions[0]],
      });
    }
  }

  selectState(value) {
    if (this.state.state.map(x => x.value).indexOf('invited') === -1 && value.map(x => x.value).indexOf('invited') > -1) {
      this.setState({
        activeFilter: false,
      });
      this.setState({
        state: [stateOptions[0]],
      });
    } else if (value) {
      this.setState({
        state: value,
      });
    } else {
      this.setState({
        state: [],
      });
    }
  }

  showStateValue(partner) {
    const states = {
      confirmed: 1,
      embedded: 2,
      converted: 3,
    };
    return states[partner.get('state')];
  }

  // TODO: Once we have all the states defined by the backend
  // clean this mess up by looping through all states instead
  // of the switch + for loop
  renderState(partner) {
    const state = partner.get('state');
    let activeBoxes = 0;
    switch (state) {
      case 'confirmed': activeBoxes = 1; break;
      case 'embedded': activeBoxes = 2; break;
      case 'converted': activeBoxes = 3; break;
      default:
        activeBoxes = 0;
    }
    let spans = [];
    for (let step = 1; step <= 3; step++) {
      const spanClass = cx({
        'state-box': true,
        'state-box--active': activeBoxes >= step,
      });
      spans.push(<span key={step} className={ spanClass }>&#9600;</span>);
    }

    return <p>{ spans }</p>;
  }


  renderPlan(plan, type) {
    if (plan.get('planType') === 'agency-payment' && type === '%') return 'Tiered';
    if (plan.get('rewardType') !== type) return null;
    const types = { '%': 'percentage', $: 'USD' };
    // since % are presented as an integer, we need to divide them by 100
    const divider = plan.get('rewardType') === '%' ? 100 : 1;
    const rewardValue = plan.get('rewardAmount') ? (plan.get('rewardAmount') / divider) : null;
    return rewardValue ? (
      <FormattedNumber
        value={ rewardValue }
        format={ types[plan.get('rewardType')] }
      />) : '';
  }

  renderConfirmed(date) {
    if (date) {
      return (
        <FormattedDate
          value={ date }
          day="numeric"
          month="long"
          year="numeric"
          hour="2-digit"
          minute="numeric"
        />
      );
    }
    return '';
  }

  render() {
    /* blinkPartner reducer happens on API return - highlight saved/updated partner set timeout to hide it after 1 second */
    const blinkId = this.props.partners.get('blinkPartner');
    if (blinkId) {
      this.unblinkPartner(blinkId);
    }
    return (
      <div>
        <header className="content-header">
          <h1>
            Partners
            <span ref="count"> ({ this.props.partners.get('data').valueSeq().reduce((prev, curr) => {
              if (this.state.activeFilter !== curr.get('active')) return prev;
              if (this.state.state.map(x => x.value).indexOf(curr.get('state')) === -1) return prev;
              return prev + 1;
            }, 0)
            })</span>
          </h1>
          <div style={{ position: 'relative', top: '-15px' }}>
            <input type="text"
              onChange={ this.filter }
              className="input--inline"
              placeholder="Search..."
            />
            <span style={ activePartnersStyle }>
              <input ref="partners-active" type="checkbox" id="partners-active" checked={ this.state.activeFilter } onChange={ this.radioActive } />
              <label htmlFor="partners-active"
                style={{ marginRight: '15px' }}
              >
                <span className="checkbox__span"></span>
                Active
              </label>
              <input ref="partners-inactive" type="checkbox" id="partners-inactive" checked={ !this.state.activeFilter } onChange={ this.radioInactive } />
              <label htmlFor="partners-inactive">
                <span className="checkbox__span"></span>
                Inactive
              </label>
            </span>
            <NavLink to="/admin/partners/invite"
              className="button button--small pull-right"
              style={{ marginRight: '0px' }}
            >Invite a partner</NavLink>
            <br />
            <br />
            <ReactSelect isMulti defaultValue={ this.state.state } onChange={ this.selectState } options={ stateOptions } />
          </div>
        </header>

        { this.props.partners.get('data').valueSeq().toJS().length === 0 ? <div className="loader loader--big"></div> :
          (<Table className="table"
            sortable
            defaultSort={{ column: 'registeredAt', direction: 'desc' }}
            filterable={ ['fullName', 'trackingName'] }
            hideFilterInput
            filterBy={ this.state.filterText }
          >
            <Thead>
              <Th column="id">id</Th>
              <Th column="fullName"><span>Full name</span></Th>
              <Th column="trackingName"><span>Tracking name</span></Th>
              <Th column="ambassadorsShortcode"><span>Ambassadors Shortcode</span></Th>
              <Th column="state"><span>State</span></Th>
              <Th column="award"><span>$PLAN</span></Th>
              <Th column="profit"><span>%PLAN</span></Th>
              <Th column="registeredAt"><span>Registered</span></Th>
              <Th column="confirmedAt"><span>Confirmed</span></Th>
              <Th column="edit"><span>Edit</span></Th>
            </Thead>
            { this.props.partners.get('data').valueSeq().map(partner => {
              /* CAUTION! Ugly hack - don't show row if partner doesn't comply to a filter
                 You also need to change partner count because it uses the same logic! */
              if (this.state.activeFilter !== partner.get('active')) return null;
              if (this.state.state.map(x => x.value).indexOf(partner.get('state')) === -1) return null;
              const partnerID = partner.get('id');
              const flashUpdated = cx({ updated: this.props.partners.get('blinkPartner') === partnerID });
              return (
                <Tr key={ `partner-${partnerID}` } className={ flashUpdated }>
                  <Td column="id" className="td--primary">{ partnerID }</Td>
                  <Td column="fullName" value={ partner.get('fullName') } className="td--large">
                    <Link to={`/admin/partners/${partnerID}`}>{ partner.get('fullName') }</Link>
                  </Td>
                  <Td column="trackingName" value={ partner.get('trackingName') }>
                    <a href="#">{ partner.get('trackingName') }</a>
                  </Td>
                  <Td column="ambassadorsShortcode" value={ partner.get('ambassadorsShortcode') }>
                    <a href="#">{ partner.get('ambassadorsShortcode') }</a>
                  </Td>
                  <Td column="state" value={ this.showStateValue(partner) }>
                    { this.renderState(partner) }
                  </Td>
                  <Td column="award" className="td--primary" value={ this.getRewardValue(partner.get('plan'), '$') }>
                    { this.renderPlan(partner.get('plan'), '$') }
                  </Td>
                  <Td column="profit" className="td--primary" value={ this.getRewardValue(partner.get('plan'), '%') }>
                    { this.renderPlan(partner.get('plan'), '%') }
                  </Td>
                  <Td column="registeredAt" value={ new Date(partner.get('registeredAt')) }>
                    <FormattedDate
                      value={ partner.get('registeredAt') }
                      day="numeric"
                      month="long"
                      year="numeric"
                      hour="2-digit"
                      minute="numeric"
                    />
                  </Td>
                  <Td column="confirmedAt" className="td--primary" value={ new Date(partner.get('confirmedAt')) }>
                    { this.renderConfirmed(partner.get('confirmedAt')) }
                  </Td>
                  <Td column="edit">
                    <Link to={`/admin/partners/${partnerID}/edit`}>
                      <img src={ editIcon } alt="Edit partner" />
                    </Link>
                  </Td>
                </Tr>
              );
            })
            }
          </Table>) }
      </div>
    );
  }
}

function select(state) {
  return {
    partners: state.admin.get('partners'),
  };
}

export default connect(select)(PartnersComponent);

/*
Temporarily disabled as we look for the best option to fetch massive amounts
of data in the admin's partner index
<Date timestamp={ partner.get('registeredAt') } />
<Th column="hits"><span># hits</span></Th>
<Th column="conversions"><span># conversions</span></Th>
<Th column="conversionRate"><span>conversion rate</span></Th>
<Td column="hits" className="td--large td--primary td--slim">
  { partner.performance.hits }
</Td>
<Td column="conversions" className="td--large td--primary">
  { partner.performance.conversions }
</Td>
<Td column="conversionRate" className="link--primary link--icon">
  <strong>{ partner.performance.conversionRate }</strong>
</Td>
*/
