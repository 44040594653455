import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Button = ({
  children,
  isActive,
  activeClass,
  handleClick,
  buttonClass,
  ...props
}) => {
  return (
    <button
      type="button"
      className={cx({ [`${activeClass}`]: isActive }, buttonClass)}
      onClick={handleClick}
      {...props}
    >{children} {isActive}</button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  activeClass: PropTypes.string,
  handleClick: PropTypes.func,
  buttonClass: PropTypes.string,
  props: PropTypes.object,
};

export default Button;
