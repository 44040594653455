import { Map, List } from 'immutable';
import { actions } from 'actions/partners/withdrawals.js';

const initialState = new Map({
  isFetching: false,
  errors: new List([]),
  data: new List(),
});

export default function partnerWithdrawalsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.PARTNER_WITHDRAWALS_FETCH:
      return state.merge({ isFetching: true });
    case actions.PARTNER_WITHDRAWALS_FETCH_SUCCESS: {
      return state.merge({ isFetching: false }).mergeIn(['data'], action.body);
    }
    case actions.PARTNER_WITHDRAWALS_FETCH_FAILURE:
      return state.merge({ isFetching: true });
    default:
      return state;
  }
}
