import keyMirror from 'keymirror';
const CALL_API = Symbol.for('Call API');

export const actions = keyMirror({
  ADMIN_WITHDRAWALS_FETCH: null,
  ADMIN_WITHDRAWALS_FETCH_SUCCESS: null,
  ADMIN_WITHDRAWALS_FETCH_FAILURE: null,
});

export function fetch(filters = {}) {
  return (dispatch) => {
    if (filters) {
      const stateFilter = filters.state ? `?status=${filters.state}` : '';
      return dispatch({
        [CALL_API]: {
          types: [
            actions.ADMIN_WITHDRAWALS_FETCH,
            actions.ADMIN_WITHDRAWALS_FETCH_SUCCESS,
            actions.ADMIN_WITHDRAWALS_FETCH_FAILURE,
          ],
          endpoint: `/admin/withdrawals${stateFilter}`,
        },
      });
    }

    return null;
  };
}
