import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Auth from 'containers/Auth.js';
import Verify from 'containers/wizard/Verify.js';
import SetPassword from 'containers/wizard/SetPassword.js';
import DashboardContainer from 'containers/dashboard/index.js';
import Settings from 'containers/settings/Settings.js';
import Account from 'containers/settings/Account.js';
import Business from 'containers/settings/Business.js';
import Payment from 'containers/settings/Payment.js';
import Creatives from 'containers/Creatives.js';
import PartnerWithdrawals from 'containers/partners/Withdrawals.js';
import Logout from 'containers/Logout.js';
import ResetPassword from 'containers/ResetPassword.js';
import UpdatePassword from 'containers/UpdatePassword.js';
import AdminWrapper from 'containers/admin/Admin.js';
import AdminDashboard from 'containers/admin/index.js';
import AdminCreatives from 'containers/admin/creatives/index.js';
import AdminCreativeUpload from 'containers/admin/creatives/Upload.js';
import AdminCreativeEdit from 'containers/admin/creatives/Edit.js';
import AdminEvents from 'containers/admin/Events.js';
import AdminWithdrawals from 'containers/admin/Withdrawals.js';

import Partners from 'containers/admin/partners/index.js';
import PartnerInvite from 'containers/admin/partners/Invite.js';
import PartnerShow from 'containers/admin/partners/Partner.js';
import PartnerEdit from 'containers/admin/partners/Edit.js';

import ModalWrapper from 'containers/Modal.js';

export default (
  <ModalWrapper>
    <Switch>
      <Route path="/verify" component={ Verify } />
      <Route path="/set-password" component={ SetPassword } />
      <Route path="/logout" component={ Logout } />
      <Route path="/reset-password" component={ ResetPassword } />
      <Route path="/update-password" component={ UpdatePassword } />

      <Auth>
        {/* <Route path="/set-password" component={ SetPassword } /> */}
        <Switch>
          <Route exact path="/" component={ DashboardContainer } />
          <Route path="/creatives" component={ Creatives } />
          <Route path="/withdrawals" component={ PartnerWithdrawals } />
        </Switch>

        {/* settings routes */}
        <Route path="/settings">
          <Settings>
            <Switch>
              <Redirect exact from="/settings" to="/settings/account" />
              <Route path="/settings/account" component={ Account } />
              <Route path="/settings/business" component={ Business } />
              <Route path="/settings/payment" component={ Payment } />
            </Switch>
          </Settings>
        </Route>

        {/* admin routes */}
        <Route path="/admin">
          <AdminWrapper>
            <Switch>
              <Route exact path="/admin" component={ AdminDashboard } />
              <Route path="/admin/creatives/:id/edit" component={ AdminCreativeEdit } />
              <Route path="/admin/creatives/upload" component={ AdminCreativeUpload } />
              <Route path="/admin/creatives" component={ AdminCreatives } />
              <Route path="/admin/partners/invite" component={ PartnerInvite } />
              <Route path="/admin/partners/:id/edit" component={ PartnerEdit } />
              <Route path="/admin/partners/:id" component={ PartnerShow } />
              <Route path="/admin/partners" component={ Partners } />
              <Route path="/admin/events" component={ AdminEvents } />
              <Route path="/admin/withdrawals" component={ AdminWithdrawals } />
            </Switch>
          </AdminWrapper>
        </Route>
      </Auth>
    </Switch>
  </ModalWrapper>
);
