import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Radio = ({
  name,
  value,
  required,
  labelText,
  isChecked,
  handleChange,
  className,
  ...props
}) => {
  return (
    <div className={cx('radio', className)}>
      <input
        className="input"
        id={value}
        name={name}
        value={value}
        required={required}
        type="radio"
        checked={isChecked}
        onChange={handleChange}
        {...props}
      />
      <label className="radio-label" htmlFor={value}>
        {labelText}
      </label>
    </div>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  props: PropTypes.object,
};

export default Radio;
