import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import DocumentTitle from 'react-document-title';
import * as QueryString from 'query-string';
import PasswordField from 'components/form/PasswordField.js';
import Flash from 'components/common/Flash.js';
import flash from 'actions/flash.js';
import Button from 'components/form/ApiButton.js';
import { updatePassword } from 'actions/user.js';

const referooLogo = require('../../img/logo.svg');

class UpdatePassword extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    flash: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { password } = this.refs.password.refs;
    const query = QueryString.parse(this.props.location.search);
    const token = query.token || '';
    const callback = () => {
      this.props.dispatch(push('/'));
      this.props.dispatch(flash('Password updated.'));
    };

    this.props.dispatch(updatePassword({ newPassword: password.value }, token, callback));
  }

  renderFlash() {
    if (this.props.flash) {
      return <Flash className="in-auth" obj={ this.props.flash } />;
    }
    return <span />;
  }

  renderErrors() {
    if (this.props.user.get('errors').size > 0) {
      return (
        <div className="form__error--block">
          <p>The email you are trying to reset password for does not exist.</p>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <DocumentTitle title="Referoo - Update Password">
        <div>
          { this.renderFlash() }
          <form className="form form--auth" onSubmit={ this.handleSubmit }>
            <header className="text--centered form__header">
              <img src={ referooLogo } alt="Referoo logo" />
            </header>
            <main className="form__inner">
              { this.renderErrors() }

              <div className="form__row">
                <label htmlFor="password">New password</label>
                <PasswordField ref="password" />
              </div>

              <div className="form__row">
                <Button
                  className="button--full button--small"
                  text="Reset password"
                  active={ this.props.user.get('isFetching') }
                  activeText="Resetting..."
                />
              </div>
            </main>
          </form>
        </div>
      </DocumentTitle>
    );
  }
}

function select(state) {
  return {
    flash: state.flash,
    user: state.user,
  };
}

export default connect(select)(UpdatePassword);
