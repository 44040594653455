export default (storage) => ({
  put(key, value, callback) {
    try {
      const stringified = JSON.stringify(value.user.toJS());
      callback(null, storage.setItem(key, stringified));
    } catch (e) {
      callback(e);
    }
  },

  get(key, callback) {
    try {
      callback(null, JSON.parse(storage.getItem(key)));
    } catch (e) {
      callback(e);
    }
  },

  del(key, callback) {
    try {
      callback(null, storage.removeItem(key));
    } catch (e) {
      callback(e);
    }
  },
});
