import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Login from 'containers/Login.js';
import { isSessionStillValid, logout } from 'actions/user.js';
import StaticModal from './StaticModal.js';

class Auth extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      timeout: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (this.props.user && this.props.user.get('authToken')) {
      this.props.dispatch(isSessionStillValid());
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.get('logout')) {
      this.props.dispatch(logout());
    }
    /* if timeout in store changes from false to true, open timeout Modal */
    if (!this.props.user.get('httpTimeout') && nextProps.user.get('httpTimeout')) {
      this.setState({
        timeout: true,
      });
    }
    /* if timeout in store changes from true to false, close timeout Modal */
    if (this.props.user.get('httpTimeout') && !nextProps.user.get('httpTimeout')) {
      this.setState({
        timeout: false,
      });
    }
  }

  closeModal() {
    this.setState({
      timeout: false,
    });
    this.forceUpdate();
  }

  render() {
    const { authToken } = this.props.user.toJS();
    if (authToken) {
      return (<div>
        <StaticModal isOpen={ this.state.timeout } closeModal={ this.closeModal }>
          <div>Connection timeout...</div>
        </StaticModal>
        <div>{ this.props.children }</div>
      </div>);
    }

    return (<div>
      <StaticModal isOpen={ this.state.timeout } closeModal={ this.closeModal }>
        <div>Connection timeout...</div>
      </StaticModal>
      <Login />
    </div>);
  }
}

function select(state) {
  return {
    user: state.user,
  };
}

export default connect(select)(Auth);
