import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { set, get, isUndefined, values } from 'lodash';
import Radio from 'components/form/Radio.js';
import Button from 'components/form/Button.js';
import TextField from 'components/form/TextField.js';
import flash from 'actions/flash.js';
import { update } from 'actions/user.js';

const getValue = (isUS, obj, key) => {
  if (isUndefined(obj)) {
    return '';
  } else if (!obj.get('holderName')) {
    return '';
  }
  return isUS ? obj.get(key, '') : '';
};
class Payment extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    errors: PropTypes.object,
    isFetching: PropTypes.bool,
    updateData: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const isUS = this.props.user.getIn(['payment', 'bank', 'country']) === 'US';
    const preferred = this.props.user.getIn(['payment', 'preferred']);
    this.state = {
      errors: [],
      touched: {},
      isFormValid: false,
      preferred,
      country: this.props.user.getIn(['payment', 'bank', 'country']) || '',
      nonUSBank: {
        preferred: 'bank',
        bank: {
          holderName: getValue(!isUS, this.props.user.getIn(['payment', 'bank']), 'holderName'),
          iban: getValue(!isUS, this.props.user.getIn(['payment', 'bank']), 'iban'),
          swift: getValue(!isUS, this.props.user.getIn(['payment', 'bank']), 'swift'),
          country: '',
        },
      },
      USBank: {
        preferred: 'bank',
        bank: {
          holderName: getValue(isUS, this.props.user.getIn(['payment', 'bank']), 'holderName'),
          swift: getValue(isUS, this.props.user.getIn(['payment', 'bank']), 'swift'),
          accountNumber: getValue(isUS, this.props.user.getIn(['payment', 'bank']), 'accountNumber'),
          routingNumber: getValue(isUS, this.props.user.getIn(['payment', 'bank']), 'routingNumber'),
          country: 'US',
        },
      },
      paypal: {
        preferred: 'paypal',
        paypal: this.props.user.getIn(['payment', 'paypal'], ''),
      },
    };

    this.togglePreferred = this.togglePreferred.bind(this);
    this.changeCountry = this.changeCountry.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.cleanPreviousForm = this.cleanPreviousForm.bind(this);
  }

  componentDidMount() {
    this.validateForm();
  }

  changeCountry(country) {
    if (this.state.country === country) {
      return;
    }
    this.setState({ country }, () => {
      this.validateForm();
    });
  }

  cleanPreviousForm() {
    if (this.state.preferred === 'bank' && this.state.country === 'US') {
      let newState = { ...this.state };
      set(newState, 'nonUSBank.bank.holderName', '');
      set(newState, 'nonUSBank.bank.iban', '');
      set(newState, 'nonUSBank.bank.swift', '');
      this.setState(newState);
    } else if (this.state.preferred === 'bank' && this.state.country === '') {
      let newState = { ...this.state };
      set(newState, 'Bank.bank.holderName', '');
      set(newState, 'Bank.bank.swift', '');
      set(newState, 'Bank.bank.accountNumber', '');
      set(newState, 'Bank.bank.routingNumber', '');
      this.setState(newState);
    }
  }

  validateForm() {
    const errors = {};
    if (this.state.preferred === 'bank' && this.state.country === 'US') {
      if (!this.state.USBank.bank.holderName) {
        errors['USBank.bank.holderName'] = 'Your name of the account holder is required';
      }
      if (!this.state.USBank.bank.swift) {
        errors['USBank.bank.swift'] = 'Your SWIFT / BIC is required';
      }
      if (!this.state.USBank.bank.accountNumber) {
        errors['USBank.bank.accountNumber'] = 'Your account number is required';
      }
      if (!this.state.USBank.bank.routingNumber) {
        errors['USBank.bank.routingNumber'] = 'Your routing number is required';
      }
    } else if (this.state.preferred === 'bank' && this.state.country === '') {
      if (!this.state.nonUSBank.bank.holderName) {
        errors['nonUSBank.bank.holderName'] = 'Your name of the account holder is required';
      }
      if (!this.state.nonUSBank.bank.iban) {
        errors['nonUSBank.bank.iban'] = 'Your IBAN is required';
      }
      if (!this.state.nonUSBank.bank.swift) {
        errors['nonUSBank.bank.swift'] = 'Your swift is required';
      }
    } else if (this.state.preferred === 'paypal') {
      if (!this.state.paypal.paypal) {
        errors['paypal.paypal'] = 'Your paypal email is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.paypal.paypal)) {
        errors['paypal.paypal'] = 'Invalid paypal email address';
      }
    }
    this.setState({ errors, isFormValid: values(errors).length === 0 && !isUndefined(this.state.preferred) });
  }

  togglePreferred(preferred) {
    this.setState({ preferred }, () => this.validateForm());
  }

  handleChange(event) {
    const { name, value } = event.target;
    let newState = set({ ...this.state }, name, value);
    this.setState({ newState }, () => this.validateForm());
  }

  handleBlur(event) {
    const { name } = event.target;
    let newState = set({ ...this.state }, `touched.${name}`, true);
    this.setState({ newState }, () => this.validateForm());
  }

  update(event) {
    event.preventDefault();
    let payment;
    if (this.state.preferred === 'bank' && this.state.country === 'US') {
      payment = this.state.USBank;
    } else if (this.state.preferred === 'bank' && this.state.country === '') {
      payment = this.state.nonUSBank;
    } else if (this.state.preferred === 'paypal') {
      payment = this.state.paypal;
    }
    log(payment);
    this.cleanPreviousForm();
    this.props.updateData({ payment });
  }

  render() {
    return (
      <div className="payment">
        <form className="form" onSubmit={ this.update.bind(this) } >
          <h1 className="bold">Settings</h1>
          <h4 className="bold">Payment information</h4>
          <p className="form__explanation text-william">
            We currently support two types of payments; We can wire transfer your earnings or send them via PayPal.
          </p>

          <h5 className="border-none">Preferred method</h5>

          <div className="flex flex-row" role="radiogroup">
            <Radio
              name="prefered"
              value="paypal"
              labelText="Paypal"
              isChecked={ this.state.preferred === 'paypal' }
              handleChange={ () => this.togglePreferred('paypal') }
              className="payment__paypal-option"
            />
            <Radio
              name="prefered"
              value="bank"
              labelText="Bank Wire"
              isChecked={ this.state.preferred === 'bank' }
              handleChange={ () => this.togglePreferred('bank') }
              className="payment__iban-option"
            />
          </div>

          <span className="divider divider--fullWidth divider--thin"></span>

          { this.state.preferred === 'paypal' && <TextField
            fieldClass="form__row"
            name="paypal.paypal"
            label="Your Paypal email"
            htmlFor="name"
            value={ this.state.paypal.paypal }
            touched={ get(this.state.touched, 'paypal.paypal', false) }
            errorText={ this.state.errors['paypal.paypal'] }
            type="email"
            handleChange={ this.handleChange }
            onBlur={ this.validateForm }
            handleBlur={ this.handleBlur }
          /> }

          { this.state.preferred === 'bank' && <div className="payment__iban-wrapper">
            <div className="flex flex-row">
              <Button
                isActive={ this.state.country === 'US' }
                activeClass="button--ghost-active"
                handleClick={ () => this.changeCountry('US') }
                buttonClass="button--medium button--round button--ghost"
              >US Bank Account</Button>
              <Button
                isActive={ this.state.country === '' }
                activeClass="button--ghost-active"
                handleClick={ () => this.changeCountry('') }
                buttonClass="button--medium button--round button--ghost"
              >Non-US Bank Account</Button>
            </div>

            { this.state.country === 'US' && <div className="payment__US-account">
              <TextField
                fieldClass="form__row"
                name="USBank.bank.holderName"
                label="Name of the account holder"
                htmlFor="name"
                value={ this.state.USBank.bank.holderName }
                touched={ get(this.state.touched, 'USBank.bank.holderName', false) }
                errorText={ this.state.errors['USBank.bank.holderName'] }
                type="text"
                handleChange={ this.handleChange }
                onBlur={ this.validateForm }
                handleBlur={ this.handleBlur }
                autoComplete="cc-name"
              />
              <TextField
                fieldClass="form__row"
                name="USBank.bank.accountNumber"
                label="Account number"
                htmlFor="accountNumber"
                value={ this.state.USBank.bank.accountNumber }
                touched={ get(this.state.touched, 'USBank.bank.accountNumber', false) }
                errorText={ this.state.errors['USBank.bank.accountNumber'] }
                type="text"
                handleChange={ this.handleChange }
                onBlur={ this.validateForm }
                handleBlur={ this.handleBlur }
                autoComplete="cc-number"
              />
              <TextField
                fieldClass="form__row"
                name="USBank.bank.routingNumber"
                label="Routing number"
                htmlFor="routingNumber"
                value={ this.state.USBank.bank.routingNumber }
                touched={ get(this.state.touched, 'USBank.bank.routingNumber', false) }
                errorText={ this.state.errors['USBank.bank.routingNumber'] }
                type="text"
                handleChange={ this.handleChange }
                onBlur={ this.validateForm }
                handleBlur={ this.handleBlur }
              />
              <TextField
                fieldClass="form__row"
                name="USBank.bank.swift"
                label="SWIFT / BIC"
                htmlFor="iban"
                value={ this.state.USBank.bank.swift }
                touched={ get(this.state.touched, 'USBank.bank.swift', false) }
                errorText={ this.state.errors['USBank.bank.swift'] }
                type="text"
                handleChange={ this.handleChange }
                onBlur={ this.validateForm }
                handleBlur={ this.handleBlur }
              />
            </div> }

            { this.state.country === '' && <div className="payment__Non-US-account">
              <TextField
                fieldClass="form__row"
                name="nonUSBank.bank.holderName"
                label="Name of the account holder"
                htmlFor="holderName"
                value={ this.state.nonUSBank.bank.holderName }
                touched={ get(this.state.touched, 'nonUSBank.bank.holderName', false) }
                errorText={ this.state.errors['nonUSBank.bank.holderName'] }
                type="text"
                handleChange={ this.handleChange }
                onBlur={ this.validateForm }
                handleBlur={ this.handleBlur }
                autoComplete="cc-name"
              />
              <TextField
                fieldClass="form__row"
                name="nonUSBank.bank.iban"
                label="IBAN"
                htmlFor="iban"
                value={ this.state.nonUSBank.bank.iban }
                touched={ get(this.state.touched, 'nonUSBank.bank.iban', false) }
                errorText={ this.state.errors['nonUSBank.bank.iban'] }
                type="text"
                handleChange={ this.handleChange }
                onBlur={ this.validateForm }
                handleBlur={ this.handleBlur }
                autoComplete="cc-number"
              />
              <TextField
                fieldClass="form__row"
                name="nonUSBank.bank.swift"
                label="SWIFT / BIC"
                htmlFor="swift"
                value={ this.state.nonUSBank.bank.swift }
                touched={ get(this.state.touched, 'nonUSBank.bank.swift', false) }
                errorText={ this.state.errors['nonUSBank.bank.swift'] }
                type="text"
                handleChange={ this.handleChange }
                onBlur={ this.validateForm }
                handleBlur={ this.handleBlur }
              />
            </div> }

          </div> }

          <div className="payment__update">
            <button className="button--small" disabled={ !this.state.isFormValid }>
              {this.props.isFetching ? 'Saving...' : 'Save changes' }
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.user.get('errors'),
    isFetching: state.user.get('isFetching'),
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateData: (data) => dispatch(update(data, () => {
      dispatch(flash('Settings updated.'));
    })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
