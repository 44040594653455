import PropTypes from 'prop-types';
import React from 'react';
import { find } from 'lodash';

const FieldError = ({ errors, attribute, code, children }) => {
  const result = find(errors, { attribute, code });

  if (result) {
    return (
      <span key={`error-${code}`} className="form__error">
        { children }
      </span>
    );
  }

  /**
   * Stateless components can't return null for now. React 0.15 is supposed to
   * fix that, but for the time being use an empty <span> or <noscript> tag.
   * https://github.com/facebook/react/issues/5355
   * TODO: Revisit this once 0.15 is out.
   */
  return <span />;
};

FieldError.propTypes = {
  errors: PropTypes.array.isRequired,
  attribute: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default FieldError;
