import PropTypes from 'prop-types';
import React from 'react';

const Chart = (props) => {
  const { width, height, children } = props;

  return (
    <svg
      width={ `${width}px` }
      height={ `${height}px` }
      viewBox={ `0 0 ${width} ${height}` }
      version="1.1"
    >
      <rect style={{ fill: '#FFF' }} width="100%" height="100%" />
      { children }
    </svg>
  );
};

Chart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.object.isRequired,
};

export default Chart;
