import PropTypes from 'prop-types';
import React from 'react';

function drawPath(points) {
  const mapped = points.map(point => `${point.x},${point.y + 10}`);
  return `M${mapped.join('L')}`;
}

const Path = (props) => {
  const { points } = props;

  return (
    <g>
      <path d={ drawPath(points) } stroke={ props.color } strokeWidth="2" fill="none" />
      { points.map((point, index) => {
        return (
          <circle key={ `hits-${index}` } cx={ point.x } cy={ point.y + 10 } r="2.5" stroke={ props.color } strokeWidth="2" fill="#FFFFFF">
            <title>{ point.value }</title>
          </circle>
        );
      }) }
    </g>
  );
};

Path.propTypes = {
  points: PropTypes.array.isRequired,
  color: PropTypes.string,
};

Path.defaultProps = {
  points: [],
  color: '#118DC9',
};

export default Path;
