const CALL_API = Symbol.for('Call API');

export const PERFORMANCE_FETCH = 'PERFORMANCE_FETCH';
export const PERFORMANCE_FETCH_SUCCESS = 'PERFORMANCE_FETCH_SUCCESS';
export const PERFORMANCE_FETCH_FAILURE = 'PERFORMANCE_FETCH_FAILURE';

export function fetch(range) {
  const rangeQuery = range ? `?start=${range[0]}&end=${range[1]}` : '';
  return {
    [CALL_API]: {
      types: [
        PERFORMANCE_FETCH,
        PERFORMANCE_FETCH_SUCCESS,
        PERFORMANCE_FETCH_FAILURE,
      ],
      endpoint: `/performance${rangeQuery}`,
    },
  };
}
