import PropTypes from 'prop-types';
import React from 'react';
import Header from 'components/common/Header.js';
import Sidebar from 'components/admin/Sidebar.js';

const Admin = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="grid">
        <Sidebar />
        <main className="content content--wide">
          { children }
        </main>
      </div>
    </div>
  );
};

Admin.propTypes = {
  children: PropTypes.object,
};

export default Admin;
