import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formatMoney } from 'accounting';
import WithdrawFunds from './WithdrawFunds';
import { fetch as fetchPerformance } from '../../actions/performance.js';

const withdrawButtonStyle = {
  backgroundColor: '#e8f7ff',
  verticalAlign: 'middle',
  position: 'absolute',
  top: '8px',
  right: '8px',
  color: '#118dc9',
  fontWeight: 'bold',
  fontSize: '12px',
};

const Item = ({ headerTitle, headerUnit, description, divider }) => {
  return (
    <div className={`agency-partnership__item ${divider}`}>
      { headerUnit && <h2 className="agency-partnership__item__unit">
        {headerUnit}
      </h2> }
      <h2 className="agency-partnership__item__header">
        { headerTitle }
      </h2>
      <p>{ description }</p>
    </div>
  );
};

Item.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  headerUnit: PropTypes.string,
  divider: PropTypes.string,
};

class AgencyPartnership extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.dispatch(fetchPerformance());
  }

  currentRewardTierDescription(user) {
    const currentTier = user.plan.tieredPercentage.currentTier;
    const maxRewardTierDescription = 'You have reached our maximum reward tier!';

    if (currentTier.topTier) {
      return maxRewardTierDescription;
    }

    return `This tier's reward %. Next tier is ${currentTier.nextTier}% for period earnings over ${formatMoney(currentTier.upperBound)}`;
  }

  render() {
    const user = this.props.user.toJS();
    if (!user || !user.performance || !user.performance.currentPeriod) {
      return <h1>Loading ...</h1>;
    }
    const visitors = user.performance.currentPeriod.referrals.visitors;
    const startDate = moment(user.performance.currentPeriod.startDate).format('MMMM Do YYYY');
    const endDate = moment(user.performance.currentPeriod.endDate).format('MMMM Do YYYY');
    const convertedVisitors = user.performance.currentPeriod.referrals.convertedVisitors;
    const visitorConversionRate = user.performance.currentPeriod.referrals.visitorConversionRate;
    const conversions = user.performance.currentPeriod.referrals.conversions;
    const rewardAmount = user.plan.tieredPercentage.currentTier.rewardAmount;
    const cdblGrossRevenueYtd = user.performance.currentPeriod.referrals.cdblGrossRevenueYtd;
    const partnerEarningsYtd = user.performance.currentPeriod.referrals.partnerEarningsYtd;
    const currentBalance = user.currentBalance;
    const revenueBreakDownReferrals = user.performance.currentPeriod.revenueBreakdown.referrals;
    const revenueBreakDownRebates = user.performance.currentPeriod.revenueBreakdown.rebates;

    return (
      <div>
        <h1>
          Performance Snapshot
        </h1>
        <section className="agency-partnership__wrap">
          <h2 className="agency-partnership__header bg-elephant">
            YOUR CURRENT PLAN PERIOD
          </h2>
          <div className="agency-partnership__items">
            <Item headerTitle="Period Start" description={startDate} />
            <Item headerTitle="Period End" description={endDate} />
          </div>
        </section>

        <div className="agency-partnership__flex-direction agency-partnership--fixed-height">
          <section className="agency-partnership__wrap grid__half">
            <h2 className="agency-partnership__header bg-jungle-green">
              REFERRED PROSPECTS PERFORMANCE
            </h2>
            <div className="agency-partnership__items flex-col">
              <Item
                headerTitle="UNIQUE VISITORS SENT"
                headerUnit={`${visitors}`}
                description="Number of visitors that you've sent"
                divider="agency-partnership__item--divider"
              />
              <Item
                headerTitle="CONVERTED VISITORS"
                headerUnit={`${convertedVisitors}`}
                description="Number of visitors converted to becoming paying clients"
                divider="agency-partnership__item--divider"
              />
              <Item
                headerTitle="VISITOR CONVERSION"
                headerUnit={`${visitorConversionRate}%`}
                description="Percentage of visitors converted to becoming paying clients"
                divider="agency-partnership__item--divider"
              />
              <Item
                headerTitle="PROJECTS COMPLETED BY REFERRALS"
                headerUnit={`${conversions}`}
                description="Total number of completed projects"
              />
            </div>
          </section>

          <section className="agency-partnership__wrap grid__half grid__last">
            <h2 className="agency-partnership__header bg-limeade">
              SUMMARY PLAN INFO
            </h2>
            <div className="agency-partnership__items flex-col">
              <Item
                headerTitle="CURRENT REWARD TIER"
                headerUnit={`${rewardAmount}%`}
                description={this.currentRewardTierDescription(user)}
                divider="agency-partnership__item--divider"
              />
              <Item
                headerTitle="TOTAL CODEABLE SPEND"
                headerUnit={formatMoney(cdblGrossRevenueYtd)}
                description="Gross revenue generated (referrals spend + your spend)"
                divider="agency-partnership__item--divider"
              />
              <Item
                headerTitle="YOUR PERIOD EARNINGS"
                headerUnit={formatMoney(partnerEarningsYtd)}
                description="Your total earnings in this annual period"
                divider="agency-partnership__item--divider"
              />

              {this.props.isAdmin ? <Item
                headerTitle="YOUR CURRENT BALANCE"
                headerUnit={formatMoney(currentBalance)}
                description="Earnings you've not yet withdrawn"
              /> :
                <div className="agency-partnership__item relative">
                  <h2 className="agency-partnership__item__unit">
                    {formatMoney(currentBalance)}
                  </h2>
                  <WithdrawFunds
                    location={this.props.location}
                    user={this.props.user}
                    withdrawButtonStyle={withdrawButtonStyle}
                  />
                  <h2 className="agency-partnership__item__header">
                    YOUR CURRENT BALANCE
                  </h2>
                  <p>Earnings you've not yet withdrawn</p>
                </div>}
            </div>
          </section>

        </div>
        <section className="agency-partnership__wrap">
          <h2 className="agency-partnership__header bg-deluge">
            SPEND BREAKDOWN
          </h2>
          <div className="agency-partnership__items">
            <Item
              headerTitle="SPEND BY REFERRED PROSPECTS"
              headerUnit={formatMoney(revenueBreakDownReferrals)}
              description="The amount spent by converted referred prospects"
            />
            <Item
              headerTitle="SPEND ON YOUR AGENCY ACCOUNT"
              headerUnit={formatMoney(revenueBreakDownRebates)}
              description="The amount you've spent on your agency client account"
            />
          </div>
        </section>

      </div>
    );
  }
}

export default connect()(AgencyPartnership);
