import PropTypes from 'prop-types';
import React from 'react';

const Flash = (props) => {
  return (
    <section className={ `flash ${props.className} ${props.obj.type}` }>
      <div className="grid">
        <p>{ props.obj.text }</p>
      </div>
    </section>
  );
};

Flash.propTypes = {
  obj: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Flash;
