import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class SingleTool extends Component {
  static propTypes = {
    creative: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  renderEditLink(id) {
    if (this.props.user.get('role') === 'admin') {
      return <Link to={ `/admin/creatives/${id}/edit` }>Edit</Link>;
    }
    return <span />;
  }

  render() {
    const creative = this.props.creative;

    return (
      <section className="creatives-tool-wrap">
        <div className="creative-tool">
          <h6 className="creative__title">
            <span className="creative__number">{ creative.get('id') }</span>
            { creative.get('name') } { this.renderEditLink(creative.get('id')) }
          </h6>
          <p style={{ paddingBottom: '1rem' }}>{ creative.get('description') }</p>
          <a className="button button--small" href={ creative.get('codeSnippet') } rel="noreferrer" target="_blank">Go to tool</a>
        </div>
      </section>
    );
  }
}
