/* eslint react/no-multi-comp:0 */
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as QueryString from 'query-string';
import { verify } from 'actions/user.js';

const crossIcon = require('../../../img/cross.svg');
const checkmarkIcon = require('../../../img/checkmark.svg');

const InProgress = () => {
  return (
    <div className="grid--breathe text--centered form--auth">
      <div className="loader loader--big breathe--bottom">Loading...</div>
      <h4>Verifying your account</h4>
      <p>Please wait while your account is being verified. This should only take a moment.</p>
    </div>
  );
};

const MissingToken = () => {
  return (
    <div className="grid--breathe text--centered form--auth">
      <img src={ crossIcon }
        className="breathe--bottom"
        alt="Missing token"
      />
      <h4>Missing or invalid token</h4>
      <p>
        We could not verify your account because the necessary token is missing from
        your address. Please make sure to copy the URL we send in the email
        correctly.
      </p>
    </div>
  );
};

const Failed = () => {
  return (
    <div className="grid--breathe text--centered form--auth">
      <img src={ checkmarkIcon }
        className="breathe--bottom"
        alt="Verification failed"
      />
      <h4>An error has occurred</h4>
      <p>
        It seems there has been an error verifying your account.
      </p>
      <p>
        Please contact our support team by clicking the blue bubble, bottom right.
      </p>
    </div>
  );
};

class Verify extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const query = QueryString.parse(this.props.location.search);
    const { token } = query;
    if (token) {
      this.props.dispatch(verify(token));
    }
  }

  componentWillReceiveProps(props) {
    log('-- Verify#componentWillReceiveProps: ', props);

    if (props.user.get('token')) {
      log('-- Verify#componentWillReceiveProps: #routing to set-password.');
      this.props.dispatch(push('/set-password'));
    }
  }

  render() {
    const { user } = this.props;
    const query = QueryString.parse(this.props.location.search);

    if (!query.token) {
      return <MissingToken />;
    }

    if (user.get('errors').size > 0) {
      return <Failed errors={ user.get('errors') } />;
    }

    return <InProgress />;
  }
}

function select(state) {
  return {
    user: state.user,
  };
}

export default connect(select)(Verify);
