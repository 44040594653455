import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/form/ApiButton.js';
import { Link } from 'react-router-dom';
import { reinvite } from 'actions/admin/partners.js';

import ConfirmButton from 'components/form/ConfirmButton.js';

const paymentType = {
  'partner-payment': 'Partner Payment',
  'reverse-reward': 'Reverse Reward',
  'agency-payment': 'Agency Plan',
};

export const defaultPlan = {
  planType: 'partner-payment',
  rewardType: '$',
  recurring: false,
  rewardAmount: '',
};

class PartnerForm extends React.Component {
  constructor(props) {
    super(props);
    this.changeRewardType = this.changeRewardType.bind(this);
    this.changePlanType = this.changePlanType.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFormValues = this.getFormValues.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleWebsiteChange = this.handleWebsiteChange.bind(this);
    this.handleRecurring = this.handleRecurring.bind(this);

    this.state = {
      partnerLoaded: false,
      partner: {
        plan: { ...defaultPlan },
      },
      partnerInvited: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.partner).length && !this.state.partnerLoaded) {
      let newState = {
        partnerLoaded: true,
        partner: nextProps.partner.toJS(),
      };
      newState.partner = {
        ...newState.partner,
        plan: {
          ...defaultPlan,
          ...newState.partner.plan,
        },
      };
      this.setState(newState);

      if (nextProps.partner) {
        this.refs.fullName.value = nextProps.partner.get('fullName');
        this.refs.email.value = nextProps.partner.get('email');
        this.refs.website.value = nextProps.partner.get('website');
        if (this.props.edit) {
          this.refs['partner-active'].checked = nextProps.partner.get('active');
        }
      }
    }
    if (nextProps.user && nextProps.user.get && !nextProps.user.get('invitingPartner') && this.props.user.get('invitingPartner')) {
      this.setState(prevState => ({
        ...prevState,
        partnerInvited: true,
      }));
      this.forceUpdate();
    }
  }

  getFormValues() {
    // debugger; // eslint-disable-line no-debugger
    let returnObject;
    const {
      fullName,
      email,
      trackingName,
      utmId,
      rewardAmount,
    } = this.refs;
    if (this.props.edit) {
      if (this.state.partner.plan.planType === 'agency-payment') {
        returnObject = {
          fullName: fullName.value,
          email: email.value,
          active: this.refs['partner-active'].checked,
          planType: this.state.partner.plan.planType,
          rewardType: this.state.partner.plan.rewardType,
          website: this.refs.website.value,
          categoryId: this.state.partner.categoryId,
        };
      } else if (this.state.partner.plan.planType === 'partner-payment') {
        returnObject = {
          plan: {
            rewardAmount: rewardAmount.value,
            rewardType: this.state.partner.plan.rewardType,
            recurring: this.state.partner.plan.recurring,
          },
          fullName: this.refs.fullName.value,
          email: this.refs.email.value,
          active: this.refs['partner-active'].checked,
          website: this.refs.website.value,
          categoryId: this.state.partner.categoryId,
          trackingName: trackingName.value,
        };
      } else {
        returnObject = {
          plan: {
            rewardType: this.state.partner.plan.rewardType,
            recurring: this.state.partner.plan.recurring,
          },
          fullName: this.refs.fullName.value,
          email: this.refs.email.value,
          utmId: utmId.value,
          active: this.refs['partner-active'].checked,
          website: this.refs.website.value,
          categoryId: this.state.partner.categoryId,
          trackingName: trackingName.value,
        };
      }
    } else if (this.state.partner.plan.planType === 'agency-payment') {
      returnObject = {
        fullName: fullName.value,
        email: email.value,
        trackingName: trackingName.value,
        utmId: utmId.value,
        planType: this.state.partner.plan.planType,
        rewardType: this.state.partner.plan.rewardType,
        website: this.refs.website.value,
        categoryId: this.state.partner.categoryId,
      };
    } else {
      returnObject = {
        fullName: fullName.value,
        email: email.value,
        trackingName: trackingName.value,
        utmId: utmId.value,
        planType: this.state.partner.plan.planType,
        rewardType: this.state.partner.plan.rewardType,
        rewardAmount: this.state.partner.plan.rewardAmount,
        recurring: this.refs.recurring.checked,
        website: this.refs.website.value,
        categoryId: this.state.partner.categoryId,
      };
    }
    return returnObject;
  }

  changeRewardType(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      partner: {
        ...this.state.partner,
        plan: {
          ...this.state.partner.plan,
          rewardType: event.target.value,
          recurring: false,
        },
      },
    });
  }

  changePlanType(event) {
    event.preventDefault();
    this.state.partner.plan.planType = event.target.value;
    if (event.target.value === 'agency-payment') {
      this.setState({
        ...this.state,
        partner: {
          ...this.state.partner,
          plan: {
            ...this.state.partner.plan,
            rewardType: 'tiered-percentage',
          },
        },
      });
    } else {
      this.setState({
        ...this.state,
        partner: {
          ...this.state.partner,
          plan: {
            ...this.state.partner.plan,
            rewardType: '$',
          },
        },
      });
    }
  }

  changeCategory(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      partner: {
        ...this.state.partner,
        categoryId: event.target.value,
      },
    });
  }

  handleWebsiteChange(event) {
    this.setState({
      ...this.state,
      partner: {
        ...this.state.partner,
        website: event.target.value,
      },
    });
  }

  handleInputChange(event) {
    this.setState({
      ...this.state,
      partner: {
        ...this.state.partner,
        plan: {
          ...this.state.partner.plan,
          rewardAmount: event.target.value,
        },
      },
    });
  }

  handleRecurring(event) {
    if (this.state.partner.plan.rewardType === '$') return;
    this.setState({
      ...this.state,
      partner: {
        ...this.state.partner,
        plan: {
          ...this.state.partner.plan,
          recurring: event.target.checked,
        },
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const formValues = this.getFormValues();
    this.props.handleSubmit(formValues);
  }

  render() {
    const edit = this.props.edit;
    const categoriesOptions = this.props.admin.getIn(['partners', 'categoriesLoaded']) ? this.props.admin.getIn(['partners', 'categories']).map(item => {
      return <option key={ item.get('id') } value={ item.get('id') }>{ item.get('name') }</option>;
    }) : '';

    return (<form className="form" onSubmit={ this.handleSubmit }>
      <div className="form__row">
        <label htmlFor="full-name">Full name</label>
        { <input type="text" id="full-name" ref="fullName" /> }
      </div>

      <div className="form__row">
        <label htmlFor="full-name">Email</label>
        { <input type="text" id="email" ref="email" /> }
      </div>

      <div className="form__row">
        <label htmlFor="full-name">Tracking name</label>
        <input
          type="text"
          id="tracking-name"
          ref="trackingName"
          defaultValue={ this.state.partner.trackingName ? this.state.partner.trackingName : '' }
          disabled={ (edit && this.state.partner.trackingName !== '') }
        />
      </div>

      { this.state.partner.rebateShortcode && <div className="form__row">
        <label htmlFor="full-name">Agency rebate refcode</label>
        { edit && this.state.partner.rebateShortcode && <div>{ this.state.partner.rebateShortcode }</div> }
      </div> }

      { edit && <div className="form__row">
        <label htmlFor="full-name">Standard refcode</label>
        {this.state.partner.standardLinkShortcode}
      </div> }

      <div className="form__row">
        <label htmlFor="full-name">UTM ID</label>
        <input
          type="text"
          id="utm-id"
          ref="utmId"
          defaultValue={ this.state.partner.utmId ? this.state.partner.utmId : '' }
          disabled={ (edit && this.state.partner.utmId !== '') }
        />
      </div>

      <div className="form__row">
        <label htmlFor="full-name">Website</label>
        <input
          type="text"
          id="website"
          ref="website"
          value={ this.state.partner.website ? this.state.partner.website : '' }
          onChange={ this.handleWebsiteChange }
        />
      </div>

      <div className="form__row">
        <label htmlFor="full-name">Category</label>
        <div className="co-form__field">
          <select name="category"
            value={ this.state.partner.categoryId }
            onChange={ this.changeCategory }
          >
            <option>Select category</option>
            { this.props.admin.getIn(['partners', 'categoriesLoaded']) ? categoriesOptions : <option>Loading...</option> }
          </select>
        </div>
      </div>

      <div className="form__row">
        <label htmlFor="full-name">Type of plan</label>

        <div className="co-form__field">
          { edit ? <div>{ paymentType[this.state.partner.plan.planType] }</div> :
            <select name="plan"
              ref="planType"
              value={ this.state.partner.plan.planType }
              onChange={ this.changePlanType }
            >
              <option value="partner-payment">Partner Payment</option>
              <option value="reverse-reward">Reverse Reward</option>
              <option value="agency-payment">Agency Plan</option>
            </select> }
        </div>

      </div>

      { this.state.partner.plan.planType !== 'agency-payment' && <div className="form__row">
        <label htmlFor="full-name">Type of reward</label>

        <div className="co-form__field">
          <select name="budget"
            value={ this.state.partner.plan.rewardType }
            onChange={ this.changeRewardType }
          >
            <option value="$">Fixed amount</option>
            { this.state.partner.plan.planType === 'partner-payment' ? <option value="%">Percentage</option> : '' }
          </select>
        </div>

      </div>}

      <div className="form__row">
        <label htmlFor="full-name">Reward</label>
        { this.state.partner.plan.planType === 'agency-payment' ?
          <div>{ edit ? `Tiered Reward Amount: ${this.state.partner.plan.rewardAmount}%` : 'As per the pre-defined tiers' }</div> :
          <div>
            <label htmlFor="amount" className="label--prefix">
              { this.state.partner.plan.rewardType }
            </label>
            <input
              type="text"
              className="input--narrow"
              id="reward-amount"
              ref="rewardAmount"
              value={ this.state.partner.plan.rewardAmount ? this.state.partner.plan.rewardAmount : '' }
              onChange={ this.handleInputChange }
            />
          </div>
        }
      </div>

      <div className="form__row">
        <input ref="recurring" type="checkbox" id="recurring" checked={ this.state.partner.plan.recurring } onChange={ this.handleRecurring } />
        <label htmlFor="recurring">
          { this.state.partner.plan.rewardType === '$' || this.state.partner.plan.planType === 'agency-payment' ? '' : <span className="checkbox__span"></span> }
          Recurring payments?
        </label>
        <span id="recurring-text">
          { this.state.partner.plan.rewardType === '$' ? <div>{ this.state.partner.plan.recurring ? 'Yes' : 'No' }</div> : '' }
          { this.state.partner.plan.planType === 'agency-payment' && <div>Yes</div> }
        </span>
      </div>

      { edit &&
        <div className="form__row">
          <input ref="partner-active" type="checkbox" id="partner-active" />
          <label htmlFor="partner-active">
            <span className="checkbox__span"></span>
            Active
          </label>
        </div>
      }

      <div className="form__row">
        { edit && this.props.user && !this.props.user.get('invitingPartner') && !this.state.partnerInvited ? <ConfirmButton
          text="Reinvite partner"
          asLink
          onConfirm={ () => this.props.dispatch(reinvite(this.props.partner.get('id'))) }
        /> : null}


        { this.props.user && this.props.user.get && this.props.user.get('invitingPartner') && !this.state.partnerInvited ? (<div>Reinviting partner...</div>) : null }
        { this.state.partnerInvited ? <div>Partner invited</div> : null }
      </div>

      <div className="form__actions">
        { edit ? <Button
          className="button--small"
          text="Save"
          active={ this.props.admin.getIn(['partners', 'isSaving']) }
          activeText="Saving..."
        /> : <Button
          className="button--small"
          text="Invite"
          active={ this.props.admin.getIn(['partners', 'isFetching']) }
          activeText="Sending..."
        /> }

        <span className="text--faded">or </span>
        <Link to="/admin/partners">cancel</Link>
      </div>
    </form>);
  }
}

PartnerForm.propTypes = {
  admin: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  partner: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
};

export default PartnerForm;
