import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-solarizedlight.css';

export default class CodeEditor extends Component {
  constructor(props) {
    super(props);

    this.onInput = this.onInput.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.state = {
      code: Prism.highlight(this.props.code, this.props.language),
      scrollTop: 0,
    };
  }

  onScroll(event) {
    this.setState({
      scrollTop: Math.floor(event.target.scrollTop),
    });
  }

  onInput() {
    const inputValue = this.refs.code.value;
    this.setState({
      code: Prism.highlight(inputValue, this.props.language),
    });
  }

  onKeyDown(event) {
    // Tab key has keyCode 9
    if (event.keyCode === 9) {
      const textarea = this.refs.code;
      const val = textarea.value;
      const startPos = textarea.selectionStart;

      textarea.value = `${val.substring(0, startPos)}  ${val.substring(startPos, val.length)}`;
      textarea.selectionStart = startPos + 2;
      textarea.selectionEnd = startPos + 2;
      event.preventDefault();
      this.setState({
        code: Prism.highlight(textarea.value, this.props.language),
      });
    }
  }

  render() {
    return (
      <div className="code-editor">
        <textarea
          id="code"
          ref="code"
          className="code-editor__textarea"
          onInput={ this.onInput }
          onKeyDown={ this.onKeyDown }
          onScroll={ this.onScroll }
          defaultValue={ this.props.code }
        />
        <pre
          className="code-editor__pre"
          style={{ top: `-${this.state.scrollTop}px` }}
        >
          <code
            ref="code-editor__code"
            className="code-editor__code language-markup"
            dangerouslySetInnerHTML={{ __html: this.state.code }}
          />
        </pre>
      </div>
    );
  }
}

CodeEditor.defaultProps = {
  language: Prism.languages.markup,
  code: '',
};

CodeEditor.propTypes = {
  language: PropTypes.object,
  code: PropTypes.string,
};
