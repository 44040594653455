import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import flash from 'actions/flash.js';
import { update } from 'actions/user.js';

class Business extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    errors: PropTypes.object,
    isFetching: PropTypes.object,
    updateData: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
  }

  update(event) {
    event.preventDefault();
    const data = {
      business: {
        name: this.refs.name.value,
        address: this.refs.address.value,
        city: this.refs.city.value,
        zip: this.refs.zip.value,
        state: this.refs.state.value,
        country: this.refs.country.value,
        vat: this.refs.vat.value,
      },
    };
    this.props.updateData(data);
  }

  render() {
    const business = this.props.user.get('business').toJS();
    const { name, address, city, zip, state, country, vat } = business;

    return (
      <form className="form" onSubmit={ this.update }>
        <h1 className="bold">Settings</h1>
        <h4 className="bold">Business information</h4>
        <p className="form__explanation text-william">
          All the fields in this form are completely optional, but please note
          we need to have your information before you will be able to make any
          withdrawals.
        </p>

        <div className="form__row">
          <label htmlFor="name">Business name</label>
          <input ref="name" type="text" id="name" defaultValue={ name } />
        </div>

        <div className="form__row">
          <label htmlFor="address">Address</label>
          <input ref="address" type="text" id="address" defaultValue={ address } />
        </div>

        <div className="form__row">
          <label htmlFor="city">City</label>
          <input ref="city" type="text" id="city" defaultValue={ city } />
        </div>

        <div className="form__row">
          <label htmlFor="zip">Zip</label>
          <input ref="zip" type="text" id="zip" defaultValue={ zip } />
        </div>

        <div className="form__row">
          <label htmlFor="state">State</label>
          <input ref="state" type="text" id="state" defaultValue={ state } />
        </div>

        <div className="form__row">
          <label htmlFor="country">Country</label>
          <input ref="country" type="text" id="country" defaultValue={ country } />
        </div>

        <div className="form__row">
          <label htmlFor="vat">VAT / TAX ID</label>
          <input ref="vat" type="text" id="vat" defaultValue={ vat } />
        </div>

        <div className="form__actions">
          <button className="button--small" disabled={this.props.isFetching}>{this.props.isFetching ? 'Saving...' : 'Save changes' }</button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.user.get('errors'),
    isFetching: state.user.get('isFetching'),
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateData: (data) => dispatch(update(data, () => {
      dispatch(flash('Settings updated.'));
    })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Business);
