import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Table, Thead, Th, Tr, Td } from 'reactable';
import { formatMoney as toMoney } from 'accounting';
import { decamelize } from 'humps';
import { maybeFetchAdminCreatives } from '../../../actions/admin/creatives.js';
import editIcon from '../../../../img/edit-pencil.svg';

function toFloat(str) {
  return parseFloat(str.replace(/€|\$|,/g, ''));
}

function sortCurrency(a, b) {
  return toFloat(a) > toFloat(b) ? 1 : -1;
}

const sortableOptions = [
  { column: 'earnings', sortFunction: sortCurrency },
  'name',
  'id',
  'conversion-rate',
  'total-conversions',
  'total-hits',
  { column: 'total-earnings', sortFunction: sortCurrency },
];

export const columns = {
  id: 'ID',
  name: 'NAME',
  totalHits: 'HITS',
  conversionRate: 'CONVERSION',
  totalConversions: 'TOTAL CON.',
  earnings: 'EARNINGS',
  totalEarnings: 'TOTAL EAR.',
  edit: 'EDIT',
};

class Creatives extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.dispatch(maybeFetchAdminCreatives());
  }

  iterateColumns() {
    return Object.keys(columns).map(key => {
      return <Th key={key} column={ decamelize(key, { separator: '-' }) }>{ columns[key] }</Th>;
    });
  }

  iterateRows() {
    const allRows = this.props.admin.getIn(['creatives', 'data']).valueSeq().map(creative => {
      return (
        <Tr key={ creative.get('id') }>
          <Td column="id" className="td--primary">{ creative.get('id') }</Td>
          <Td column="name" className="td--primary">{ creative.get('name') }</Td>
          <Td column="total-hits" className="td--primary">{ creative.getIn(['performance', 'totalHits']) }</Td>
          <Td column="conversion-rate" className="td--primary">{ creative.getIn(['performance', 'conversionRate']) }</Td>
          <Td column="total-conversions" className="td--primary">{ creative.getIn(['performance', 'totalConversions']) }</Td>
          <Td column="earnings" className="td--primary">{ toMoney(creative.getIn(['performance', 'codeableEarnings'])) }</Td>
          <Td column="total-earnings" className="td--primary">{ toMoney(creative.getIn(['performance', 'totalEarnings'])) }</Td>
          <Td column="edit" className="td--primary">
            <Link to={`/admin/creatives/${creative.get('id')}/edit`}>
              <img src={ editIcon } alt="Edit creative" />
            </Link>
          </Td>
        </Tr>
      );
    });
    return allRows;
  }

  render() {
    return (
      <div>
        <header className="content-header">
          <NavLink to="/admin/creatives/upload"
            className="button button--small pull-right"
          >Upload a creative</NavLink>
          <h1>Creatives</h1>
        </header>
        <Table className="table" sortable={ sortableOptions }>
          <Thead>
            { this.iterateColumns() }
          </Thead>
          { this.iterateRows() }
        </Table>
      </div>
    );
  }
}

function select(state) {
  return {
    admin: state.admin,
  };
}

export { Creatives };
export default connect(select)(Creatives);
