import React from 'react';
import { NavLink, Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <nav className="sidebar__nav">
        <Link className="sidebar__link" to="/admin">
          Dashboard
        </Link>
        <NavLink className="sidebar__link" to="/admin/partners">Partners</NavLink>
        <NavLink className="sidebar__link" to="/admin/withdrawals">Withdrawal Requests</NavLink>
        <NavLink className="sidebar__link" to="/admin/creatives">Creatives</NavLink>
        <NavLink className="sidebar__link" to="/admin/events">Events</NavLink>
      </nav>
    </aside>
  );
};

export default Sidebar;
