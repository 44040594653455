import keyMirror from 'keymirror';

export const actions = keyMirror({
  RANGE: null,
});

export function changeDatepickerRange(datepickerRange) {
  return (dispatch) => {
    dispatch({ type: actions.RANGE, datepickerRange });
  };
}

