import PropTypes from 'prop-types';
import React from 'react';

export default class ConfirmButton extends React.Component {
  static propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    // displayed normally, before confirming, while active
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    className: PropTypes.string,
    asLink: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.onCancel = (props.onCancel ? props.onCancel.bind(this) : () => {});
    this.onConfirm = (props.onConfirm ? props.onConfirm.bind(this) : () => {});
    this.className = props.className;
    this.text = props.text;
    this.state = {
      active: false,
    };

    this.activate = () => {
      if (!this.state.active) {
        this.setState({ active: true });
      }
    };
    this.confirm = () => {
      this.onConfirm();
      this.setState({ active: false });
    };
    this.cancel = () => {
      this.onCancel();
      this.setState({ active: false });
    };
    this.isActive = () => {
      return this.state.active;
    };
  }

  render() {
    const isActive = this.isActive();
    if (this.props.asLink) {
      return (
        <span className="confirm-button--wrapper">
          <a
            className={ this.className }
            onClick={ this.activate }
            disabled={ isActive }
          >
            {this.text}
          </a>

          { isActive ?
            <span>
              <button type="button" className="button--min" onClick={this.confirm}>Confirm</button>
              <button type="button" className="button--min button--negatory" onClick={this.cancel}>Cancel</button>
            </span>
            : ''}
        </span>
      );
    }

    return (
      <span className="confirm-button--wrapper">
        <button
          type="button"
          className={ this.className }
          onClick={ this.activate }
          disabled={ isActive }
        >
          {this.text}
        </button>

        { isActive ?
          <span>
            <button type="button" className="button--min" onClick={this.confirm}>Confirm</button>
            <button type="button" className="button--min button--negatory" onClick={this.cancel}>Cancel</button>
          </span>
          : ''}
      </span>
    );
  }
}
