import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import config from 'config';
import CodeSnippet from 'components/common/CodeSnippet.js';

export default class SingleCreative extends Component {
  static propTypes = {
    creative: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  renderEditLink(id) {
    if (this.props.user.get('role') === 'admin') {
      return <Link to={ `/admin/creatives/${id}/edit` }>Edit</Link>;
    }
    return <span />;
  }

  render() {
    const creative = this.props.creative;

    return (
      <section className="creatives-wrap">
        <div className="creative">
          <h6 className="creative__title">
            <span className="creative__number">{ creative.get('id') }</span>
            { creative.get('name') } { this.renderEditLink(creative.get('id')) }
          </h6>
          <p>{ creative.get('description') }</p>
          <CodeSnippet code={ creative.get('codeSnippet') } />
        </div>

        <div className="creative creative--last">
          <img
            className="creative__image"
            style={{ maxWidth: '100%' }}
            src={ `${config.apiUrl}${creative.getIn(['asset', 'self'])}` }
            alt={ name }
          />
        </div>
      </section>
    );
  }
}
