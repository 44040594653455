import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <nav className="sidebar__nav">
        <NavLink className="sidebar__link" to="/settings/account" activeClassName="active">
          Account
        </NavLink>
        <NavLink className="sidebar__link" to="/settings/business">Business</NavLink>
        <NavLink className="sidebar__link" to="/settings/payment">Payment</NavLink>
      </nav>
    </aside>
  );
};

export default Sidebar;
