import { actions } from 'actions/creatives.js';
import { Map, List } from 'immutable';
import mapper from 'reducers/mapper.js';

export const initialState = new Map({
  isFetching: false,
  errors: new List([]),
  data: new Map(),
});

export default function creativesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATIVES_FETCH:
      return state.merge({ isFetching: true });
    case actions.CREATIVES_FETCH_SUCCESS: {
      const newData = mapper(action.body.results.creatives);
      return state.merge({ isFetching: false }).mergeIn(['data'], newData);
    }
    default:
      return state;
  }
}
