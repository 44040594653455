import { Map, List } from 'immutable';
import { actions } from 'actions/admin/partners.js';
import mapper from 'reducers/mapper.js';
import { GENERIC_ERROR } from 'actions/flash.js';

export const initialState = new Map({
  isFetching: false,
  categoriesLoaded: false,
  errors: new List([]),
  data: new Map(),
  isSaving: false,
  blinkPartner: 0,
});

let partner;
let partnerObj;

export default function partnersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADMIN_PARTNERS_FETCH:
    case actions.ADMIN_PARTNER_INVITE:
      return state.merge({ isFetching: true });
    case actions.ADMIN_PARTNERS_FETCH_SUCCESS:
    case actions.ADMIN_PARTNER_INVITE_SUCCESS: {
      const newData = mapper(action.body.results.partners);
      return state.mergeDeep({ isFetching: false, data: newData });
    }
    case actions.ADMIN_PARTNER_INVITE_FAILURE:
      return state.merge({ isFetching: false });
    case actions.ADMIN_PARTNER_UPDATE:
      return state.merge({ isSaving: true });
    case actions.ADMIN_PARTNER_UPDATE_SUCCESS:
      partner = action.body.results.user;
      partnerObj = {};
      partnerObj[partner.id] = partner;
      return state.merge({ isSaving: false, blinkPartner: partner.id }).mergeIn(['data'], partnerObj);
    case actions.ADMIN_PARTNER_UPDATE_FAILURE:
      return state.merge({ isSaving: false });
    case actions.ADMIN_GET_CATEGORIES_SUCCESS: {
      return state.merge({ categories: action.body, categoriesLoaded: true });
    }
    case GENERIC_ERROR:
      return state.merge({ isFetching: false, isSaving: false });
    case actions.UNBLINK_PARTNER:
      return state.merge({ blinkPartner: 0 });
    default:
      return state;
  }
}
