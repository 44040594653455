import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { invite, getCategories } from 'actions/admin/partners.js';
import flash from 'actions/flash.js';
import PartnerForm from 'components/form/PartnerForm.js';

class PartnerInvite extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getCategories());
  }

  handleSubmit(formValues) {
    const callback = () => {
      this.props.dispatch(push('/admin/partners'));
      this.props.dispatch(flash('Partner invited.'));
    };

    this.props.dispatch(invite(formValues, callback));
  }

  render() {
    const isEdit = false;
    return (
      <div>
        <h1>Invite a partner</h1>
        <p className="form__explanation">
          Use this form to invite a new partner. No special character or spaces and all lower
          case in UTM and tracking name.
        </p>
        <PartnerForm
          admin={ this.props.admin }
          edit={ isEdit }
          partner={ {} }
          user={ {} }
          handleSubmit={ this.handleSubmit }
        />
      </div>
    );
  }
}

function select(state) {
  return {
    admin: state.admin,
  };
}

export default connect(select)(PartnerInvite);
