import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { scaleTime, scaleLinear } from 'd3-scale';
import { timeDay } from 'd3-time';
import Grid from './Grid.js';
import Chart from './Chart.js';
import DataSeries from './DataSeries.js';

export default class LineChart extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    label: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      width: 580,
      height: parseInt(props.height, 10) || 200,
    };

    /**
     * We need to pre-bind the resize function, otherwise removeEventListener
     * gets confused and doesn't unsubscribe the correct function.
     * See http://stackoverflow.com/questions/32121231/ for more info
     */
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    // See? Not double colon here. (in this.resize) :)
    window.removeEventListener('resize', this.resize);
  }

  resize() {
    const width = ReactDOM.findDOMNode(this).offsetWidth;
    if (width) this.setState({ width });
  }

  render() {
    const { data } = this.props;

    // Return an empty div because it spans the whole width so we can calculate
    // the container width for when the data comes in. Result: proper graph. YAY!
    if (!data || data.length === 0) return <div />;

    // Default to 10 for when the graph is empty (all values are at 0)
    const max = data
      .map(datum => datum[this.props.type])
      .reduce((prev, curr) => { return curr > prev ? curr : prev; }) || 10;

    const xScale = scaleTime()
      .domain([new Date(data[0].date),
        timeDay.offset(new Date(data[data.length - 1].date), 1)])
      .rangeRound([40, this.state.width]);

    // const relativeScale = Math.pow(10, max.toString().length - 1);

      // .domain([0, (Math.floor(max / relativeScale) + 1) * relativeScale])
    const yScale = scaleLinear()
      .domain([0, max / 0.85])
      .range([this.state.height - 60, 0]);

    // Combine and extend props so we can pass it to children as shorthand
    const mergedProps = Object.assign({}, this.props, this.state, { xScale, yScale });

    // We need to have a wrapping div here for resizing, because it'll span the
    // whole width automatically.
    return (
      <div>
        <Chart { ...this.state }>
          <Grid { ...mergedProps }>
            <DataSeries show={ this.props.type } index="1" { ...mergedProps } />
          </Grid>
        </Chart>
      </div>
    );
  }
}
